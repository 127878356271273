import {Box, Button, styled, TextField, Typography} from "@mui/material";

export const MuiTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  fontWeight:400,
  fontFamily: 'Raleway',
  color: '#000000',
  marginTop:'8px'
}));

export const MuiSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight:400,
  fontFamily: 'Montserrat',
  color: '#000000',
}));

export const MuiLabel = styled(Typography)(({ theme }) => ({
  fontSize:'14px',
  fontFamily:'Montserrat',
  fontWeight:400,
  marginLeft:'8px',
  marginTop:'3px',
  color: 'black'
}));

export const MuiName = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontSize:'16px',
  fontWeight:400,
  color: 'black'
}));

export const MuiDescription = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontSize:'10px',
  fontWeight:400,
  color: 'black'
}));

export const MuiCharCountText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: 8,
  right: 8,
  fontSize: 10,
  fontFamily: 'Montserrat',
  fontWeight: 500,
}));

export const MuiCharCountTitle = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: 8,
  right: 8,
  fontSize: 10,
  fontFamily: 'Montserrat',
  fontWeight: 500,
}));

export const MuiFileName = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontSize:'11px',
  fontWeight:400,
  color:'#616161',
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'normal',
  textAlign: 'center',
}));

export const MuiErrorText = styled(Typography)(({ theme }) => ({
  color: 'red',
  fontSize: '10px',
  position: 'absolute',
  bottom: '4px',
}));

export const MuiFileUploadedText = styled(Typography)(({ theme }) => ({
  fontSize: '10px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign:'left',
  position: 'absolute',
  bottom: '4px',
  fontFamily:'Montserrat',
  fontWeight:400,
  color:'#616161',
}));

export const MuiButtonSelectedFile = styled(Button)(({ theme }) => ({
  position: 'absolute',
  borderRadius: '13px',
  right: '8px',
  top: '8px',
  fontSize: '10px',
  padding: '4px 12px 4px 12px',
}));

export const MuiBoxFile = styled(Box)(({ theme }) => ({
  border: '2px dashed #ccc',
  borderRadius: '12px',
  padding: '10px 16px 10px 16px',
  backgroundColor: '#f9f9f9',
  textAlign: 'center',
  position: 'relative',
  height: '50px',
  width: '420px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  marginTop:'16px'
}));

export const MuiTitleField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '& .MuiInputBase-root': {
    height: '56px',
    borderRadius: '5px'
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.5px'
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.4px'
  },
  '& input': {
    fontSize: '16px',
    fontFamily: 'Montserrat',
  },
  '& legend': {
    fontSize: 12
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
}));

export const MuiDescriptionField = styled(TextField)(({ theme }) => ({
  width: '100%',
  height: '200px',
  '& .MuiInputBase-root': {
    borderRadius: '4px',
    height: '100%',
    padding: '24px',
    display: 'flex',
    alignItems: 'flex-start',
  },
  '& .MuiOutlinedInput-root': {
    height: '100%',
    width: '100%',
    '& textarea': {
      width: '100%',
      height: '100%',
      color: '#424242',
      fontSize: 16,
      fontFamily: 'Montserrat',
      lineHeight: 1.5,
      boxSizing: 'border-box',
      overflow: 'auto',
      resize: 'none',
    },
    '& fieldset': {
      borderColor: 'black',
    },
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
}));

export const labelStyle = {
  color: '#424242',
  fontSize:12,
  fontFamily:'Montserrat',
};

export const iconStyle = {
  marginRight: 1,
  color:'#616161'
};

export const stepperStyle = {
  paddingLeft: 1,
  paddingRight: 1
};

export const stepIconStyle = {
  '& .MuiStepIcon-text': {
    fill: '#FFFFFF',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: 'Montserrat'
  }
};
