import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import CategoryWidgetUI from './CategoryWidgetUI';
import WrapperWidgetUI from '../WrapperWidgetUI';
import {
  setAnalysisVisualizationType,
  setReferenceAggTopicSegment, setSegmentationCategories,
  setSegmentationFilter,
  setUpdateQA
} from '../../../../store/appSlice';
import {status200} from '../../../../api/status.utils';
import {Tooltip, useTheme} from "@mui/material";
import {MuiInfoOutlinedIcon} from '../../styles/categoryWidgetUI';
import {useCache} from "../../../providers/CacheContext";
import enginePaths from "../../../../api/enginePaths";
import axiosEngineInstance from "../../../../api/axios/axiosEngineInstance";
import JoinFullRoundedIcon from '@mui/icons-material/JoinFullRounded';

/**
 * Renders a <CategoryWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 */
function CategoryWidget({
                          id,
                          title,
                          column,
                          answerVar,
                          dataSource,
                          dataRegion,
                          wrapperProps,
                          enableSegmentationAggregation,
                          weight,
                          policyId,
                          description
                        }) {
  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  const [location, setLocation] = useState(null);
  const folded = useSelector(state => state.app.widgetFoldedState[id]);
  const activeCategoryFilters = useSelector(state => state.app.segmentationFilter);
  const activeStatisticFilters = useSelector(state => state.app.statisticFilter);
  const activeSimilarityFilters = useSelector(state => state.app.similarityFilter);
  const activeInsightFilters = useSelector(state => state.app.insightsFilter);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const similarityQuery = useSelector(state => state.app.similarityQuery);
  const referenceAggTopicSegment = useSelector(state => state.app.referenceAggTopicSegment);
  const barChartFilter = useSelector(state => state.app.barChartFilter);
  const [policyReferenceSegment, setPolicyReferenceSegment] = useState(referenceAggTopicSegment[policyId] || null);
  const segmentationCategories = useSelector(state => state.app.segmentationCategories);
  const analysisVisualizationType = useSelector(state => state.app.analysisVisualizationType);
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const theme = useTheme();
  const {cache, setCacheData} = useCache();

  const fetchData = async (data) => {
    const cache_key = JSON.stringify(data);
    if (cache[cache_key]) {
      return cache[cache_key]
    }
    const respData = await axiosEngineInstance.post(enginePaths.aggregate_category, data, status200).then((resp) => {
      return resp.data
    });
    setCacheData(cache_key, respData)
    return respData
  }

  const loadWidgetData = async (bypass = true) => {
    let categoriesData = [];
    if (folded && bypass) return categoriesData;
    // console.log("Loading widget data")
    setIsLoading(true);

    let localSegmentationFilter = {...activeCategoryFilters, ...(activeInsightFilters[policyId] || {}), ...(barChartFilter || {})};
    delete localSegmentationFilter[column];

    let localStatisticFilter = {...activeStatisticFilters};
    let localSimilarityQuery = similarityQuery[policyId] || {};
    let localSimilarityFilters = activeSimilarityFilters[policyId] || {};

    if (treeMapFilter) {
      Object.keys(treeMapFilter).forEach((key) => {
        Object.assign(localSegmentationFilter, treeMapFilter[key]);
      });
    }

    try {
      const requestData = {
        surveyId: dataSource,
        attribute: column,
        segmentation: localSegmentationFilter,
        statistics: localStatisticFilter,
        weight: weight,
        questionId: answerVar,
        similarity_query: localSimilarityQuery,
        similarity: localSimilarityFilters,
        region: dataRegion || "US",
      };

      const response = await fetchData(requestData);
      const localData = response || {rows: []};

      if (localData.rows?.length > 0) {
        setData(localData.rows);
        categoriesData = localData.rows;
        dispatch(setSegmentationCategories({
          ...segmentationCategories,
          [column]: localData.rows.map(d => d.name)
        }));
      }
    } catch (error) {
      console.error("Error fetching categories for ", column, ": ", error);
    }

    setIsLoading(false);
    return categoriesData;
  };

  React.useEffect(() => {
    loadWidgetData();
  }, [
    dataSource,
    column,
    weight,
    activeCategoryFilters,
    activeStatisticFilters,
    selectedCategories,
    activeSimilarityFilters,
    similarityQuery,
    treeMapFilter,
    activeInsightFilters,
    folded,
    barChartFilter
  ]);

  useEffect(() => {
    if (column && activeCategoryFilters) {
      let localSegmentationFilter = {...activeCategoryFilters}
      let found = false;
      for (var prop in localSegmentationFilter) {
        if (prop === column) {
          found = true;
          setSelectedCategories([...localSegmentationFilter[prop].map((d) => `${d}`)]);
        }
      }
      if (!found) {
        setSelectedCategories([]);
      }
    }

  }, [column, activeCategoryFilters])


  const handleSelectedCategoriesChange = (categories) => {
    let localSegmentationFilter = {...activeCategoryFilters}
    if (categories.length > 0) {
      localSegmentationFilter[column] = data.map((d) => d.name).filter((d) => categories.includes(`${d}`));
    } else {
      localSegmentationFilter[column] = [];
    }
    dispatch(setSegmentationFilter(localSegmentationFilter));
    dispatch(setUpdateQA(true));
    setSelectedCategories(categories);
  }

  const updateReferenceAggTopicSegment = () => {
    const updatedReferenceAggTopicSegment = {
      ...referenceAggTopicSegment
    }
    let currentValue = updatedReferenceAggTopicSegment[policyId] || null
    let newValue = currentValue !== column ? column : null
    setPolicyReferenceSegment(newValue);
    dispatch(setReferenceAggTopicSegment({[policyId]: newValue}))
    if (analysisVisualizationType[policyId] !== "bars") {
      const updatedAnalysisVisualizationType = {
        ...analysisVisualizationType
      }
      updatedAnalysisVisualizationType[policyId] = "bars"
      dispatch(setAnalysisVisualizationType(updatedAnalysisVisualizationType))
    }
    if (newValue !== null) {
      if (!data || !data.length) {
        loadWidgetData(false).then(catData => {
          if (!catData || !catData.length) return
          let localSegmentationFilter = {...activeCategoryFilters}
          if (!localSegmentationFilter?.[column] || localSegmentationFilter?.[column]?.length === 0) {
            localSegmentationFilter[column] = catData.map((d) => d.name);
            dispatch(setSegmentationFilter(localSegmentationFilter));
            setSelectedCategories(catData.map((d) => `${d.name}`));
          }
          dispatch(setUpdateQA(true));
        });
      } else {
        if (!activeCategoryFilters?.[column] || activeCategoryFilters?.[column]?.length === 0) {
          handleSelectedCategoriesChange(data.map((d) => `${d.name}`));
        }
      }

    } else {
      handleSelectedCategoriesChange([]);
    }
  }

  useEffect(() => {
    if (enableSegmentationAggregation) {
      let currentValue = referenceAggTopicSegment[policyId] || null;
      if (currentValue !== policyReferenceSegment) {
        setPolicyReferenceSegment(currentValue);
      }
    }
  }, [referenceAggTopicSegment])

  useEffect(() => {
    if (analysisVisualizationType[policyId] !== "bars") {
      let currentValue = null;
      if (currentValue !== policyReferenceSegment) {
        setPolicyReferenceSegment(currentValue);
        const updatedReferenceAggTopicSegment = {
          ...referenceAggTopicSegment
        }
        updatedReferenceAggTopicSegment[policyId] = null
        dispatch(setReferenceAggTopicSegment(updatedReferenceAggTopicSegment))
      }
    }
  }, [analysisVisualizationType]);

  wrapperProps.actions = [{
    id: 'a3',
    icon: <MuiInfoOutlinedIcon
      color={"grey"}/>,
    action: () => setShowDescription(prevState => !prevState),
    setModalLocation: setLocation,
    disabled: false,
    show: description !== '',
  }];
  if (enableSegmentationAggregation) {
    wrapperProps.actions.push({
      id: 'a4',
      icon: <Tooltip
        title={`Perform a cross-tab analysis between the segment "${title}" and the topics of the current question`}
        placement={'bottom-end'}
        sx={{
          '& .MuiTooltip-tooltip': {
            fontSize: 10,
            fontFamily: 'Montserrat',
            letterSpacing: '0.005em'
          },
        }}
      >
        <JoinFullRoundedIcon
          color={policyReferenceSegment === column ? "primary" : "#616161"}
          sx={{cursor: 'pointer', width: '16px', height: '16px'}}/>
      </Tooltip>,
      action: () => updateReferenceAggTopicSegment(),
      disabled: false,
      show: true,
      // backgroundColor: policyReferenceSegment === column ? "#E0E0E0" : "transparent",
    })
  }
  return (
    <WrapperWidgetUI
      title={title}
      widgetId={id}
      isLoading={isLoading} {...wrapperProps}
      filterEnabled={selectedCategories?.length > 0 && selectedCategories.length < data.length}
      showDescription={showDescription}
      setShowDescription={setShowDescription}
      location={location}
      description={description}
    >
      {(data && data.length > 0) && (
        <>
          <CategoryWidgetUI
            data={data.map((d) => {
              return {name: `${d.name}`, value: d.value}
            })}
            selectedCategories={selectedCategories}
            onSelectedCategoriesChange={handleSelectedCategoriesChange}
            color={{
              type: "simple",
              simpleColor: theme.palette.secondary.main,
              customColors: [],
              enabled: true
            }}//poner color fijo o de la organizacion que puede estar en redux o algun provider
            widgetId={id}
            filterable={true}
            isReferenceSegment={policyReferenceSegment === column}
            maxItems={7}
            searchable={false}
            order={"fixed"}
            isLoading={isLoading}
          />
        </>
      )}
      {/* </WidgetWithAlert> */}
    </WrapperWidgetUI>
  );
}

CategoryWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
  wrapperProps: PropTypes.object,
  noDataAlertProps: PropTypes.object
};


export default CategoryWidget;
