import {Box, Button, Grid2, Modal, Typography} from "@mui/material";
import {MuiKButton} from "./styles/classificationModal";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";


export const ClassificationModal = ({
                                      open,
                                      onClose,
                                      submitClassification,
                                      k,
                                      updateK,
  amountCategories
                                    }) => {
  const {t} = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        display: 'flex',
        width: '361px',
        borderRadius: '28px',
        backgroundColor: '#fff',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '24px'
      }}>
        <Grid2 container direction={'column'} sx={{width: '100%'}}
               rowGap={'24px'}>
          <Grid2 container item direction='row' sx={{width: '100%'}}
                 alignItems={'center'}>
            <Typography sx={{
              fontFamily: 'Raleway',
              fontSize: '25px',
              color: '#212121',
              textAlign: 'left',
            }}>
              Max. categories per quote:
            </Typography>
          </Grid2>
          <Grid2 container item direction='row' columnGap={'16px'}>
            {Array(Math.min(3,amountCategories)).fill(0).map((_,index)=>(
              <MuiKButton variant={'outlined'} selected={k === index+1}
                          onClick={() => updateK(index+1)}>
                {index+1}
              </MuiKButton>
            ))}
          </Grid2>
          <Grid2 container item direction='row' columnGap={'8px'}
                 justifyContent={'flex=start'} alignContent={'center'}>
            <Button
              onClick={onClose}
              variant={'text'}
              sx={{
                height: '37px',
                backgroundColor: '#FFFFFF',
              }}
            >
              {t('cancel_btn')}
            </Button>
            <Button
              variant={'outlined'}
              onClick={() => {
                onClose();
                submitClassification();
              }}
              sx={{
                height: '37px',
                borderRadius: '20px',
                padding: '10px 24px'
              }}
            >
              {t('segment_answers')}
            </Button>
          </Grid2>
        </Grid2>
      </Box>
    </Modal>)
}
