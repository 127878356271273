import React, {useEffect, useState} from 'react';
import 'assets/scss/loader.css';
import {useLoading} from '../providers/LoadingProvider';
import {useDispatch, useSelector} from 'react-redux';
import {Box, LinearProgress, Stack, Typography} from "@mui/material";
import {useTheme} from "../providers/CustomThemeProvider";
import growing from 'assets/animations/growing.json';
import undetermined from 'assets/animations/undetermined.json';
import thinkingTree from 'assets/animations/thinking_tree.json';
import Lottie from "lottie-react";
import {useTranslation} from "../providers/TranslationProvider";
import {setTreemapLoadingProgress} from "../../store/appSlice";

const treemapLoadingProgressMessages = {
  english: [
    "Collecting raw survey responses...",
    "Analyzing responses with our AI models...",
    "Evaluating the relevance of each response...",
    "Searching for key topics in the responses...",
    "Aggregating data for comprehensive analysis...",
    "Compiling detailed analysis and insights...",
    "Finalizing the visualization of insights...",
    "We are ready to present your insights!"
  ],
  spanish: [
    "Recopilando respuestas de encuestas...",
    "Analizando respuestas con nuestros modelos de inteligencia artificial...",
    "Evaluando la relevancia de cada respuesta...",
    "Buscando temas clave en las respuestas...",
    "Agregando datos para una análisis completo...",
    "Compilando detalles de análisis y información...",
    "Finalizando la visualización de información...",
    "Estamos listos para presentar su información..."
  ],
  french: [
    "Collecte des réponses brutes à l'enquête...",
    "Analyse des résponses avec nos modeèles d'intelligence artificielle...",
    "Évaluation de la rélevance de chaque résponse...",
    "Recherche de titres clés dans les résponses...",
    "Agregation des données pour un analyse complet...",
    "Compilation des détails d'analyse et des informations...",
    "Finalisation de la visualisation des informations...",
    "Nous sommes présents pour présenter vos informations..."
  ],
  portuguese: [
    "Coletando respostas de encuesta...",
    "Analisando respostas com nossos modelos de inteligência artificial...",
    "Evaluando a relevância de cada resposta...",
    "Pesquisando temas chaves nas respostas...",
    "Agregando dados para um análise completa...",
    "Compilando detalhes de analise e informações...",
    "Finalizando a visualização de informações...",
    "Estamos prontos para apresentar suas informações..."
  ],
  arabic: [
    "Collecting raw survey responses...",
    "Analyzing responses with our AI models...",
    "Evaluating the relevance of each response...",
    "Searching for key topics in the responses...",
    "Aggregating data for comprehensive analysis...",
    "Compiling detailed analysis and insights...",
    "Finalizing the visualization of insights...",
    "We are ready to present your insights!"
  ]
}
const ProsperiaLoader = () => {
  const {
    isLoading,
    isSearchLoading,
    treeMapLoading,
    isTopicInferenceLoading,
    isClassificationLoading,
    treeMapCallId,
    setTreeMapCallId,
    title,
    progress,
    progressMessage,
    showProgressBar,
  } = useLoading();
  const dispatch = useDispatch();
  const [actualProgress, setActualProgress] = useState(0);
  const treemapLoadingProgress = useSelector(state => state.app.treemapLoadingProgress);
  const treemapIsLoading = Object.values(treeMapLoading).reduce((acc, currentValue) => acc || currentValue, false);
  const {lng} = useTranslation();
  const treemapLoadingMessages = treemapLoadingProgressMessages[lng];
  const [currentMessage, setCurrentMessage] = useState(treemapLoadingMessages[0]);
  const [progressAnimation, setProgressAnimation] = useState(undetermined);
  const {theme} = useTheme();
  const targetScheme = useSelector(state => state.app.targetScheme);
  const maxTreemapProgress = targetScheme?.policies?.reduce((acc, currentValue) => acc + currentValue.size, 0) || 1;

  useEffect(() => {
    if (isSearchLoading) {
      // Reset progress to 0 whenever search starts
      setActualProgress(0);
      setCurrentMessage('We are analyzing the relevant quotes to provide a response...');

      const startTime = performance.now();
      const duration = 15000; // 6 seconds
      const endTime = startTime + duration;

      const intervalId = setInterval(() => {
        const now = performance.now();
        const elapsed = now - startTime;
        const fraction = elapsed / duration;

        if (fraction >= 1) {
          // We reached 100%
          setActualProgress(100);
          clearInterval(intervalId);
          setCurrentMessage('The analysis is complete...');
        } else {
          // Progress fraction * 100
          setActualProgress(fraction * 100);
          setCurrentMessage(`Compiling the most relevant insights for your query... ${Math.floor(fraction * 100)}%`);
        }
      }, 1000);

      // Cleanup if isSearchLoading goes false OR component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isSearchLoading]);

  useEffect(() => {
    if (isSearchLoading) return;
    setTimeout(() => {
      let isTreeLoading = false;
      Object.keys(treeMapLoading).forEach(key => {
        isTreeLoading = isTreeLoading || treeMapLoading[key];
      });
      if (isTreeLoading) {
        // console.log("Progress update cycle")
        let progress = 0;
        Object.keys(treemapLoadingProgress).forEach(key => {
          progress += treemapLoadingProgress[key];
        });
        progress = Math.max(actualProgress, progress);
        progress = Math.min(progress, maxTreemapProgress);

        setActualProgress((progress / maxTreemapProgress) * 100);
        const amountMessages = treemapLoadingMessages.length;
        if (progress > 0) {
          const progressFraction = progress / 100;
          const messageIndex = Math.floor(progressFraction * (amountMessages - 1));
          setCurrentMessage(treemapLoadingMessages[messageIndex]);
        }
      } else if ((isTopicInferenceLoading || isClassificationLoading) && showProgressBar) {
        setActualProgress(progress);
        setCurrentMessage(progressMessage);
      } else if(!isSearchLoading && !isClassificationLoading && !isTopicInferenceLoading && !treemapIsLoading) {
        setActualProgress(0);
        setCurrentMessage('');
        const callIds = {...treeMapCallId}
        for (let key of Object.keys(treemapLoadingProgress)) {
          dispatch(setTreemapLoadingProgress({id: key, progress: 0}));
          callIds[key] = null;
        }
        setTreeMapCallId(callIds);
      }
    }, 0.5);
  }, [treemapLoadingProgress, treeMapLoading, isTopicInferenceLoading, isClassificationLoading, progress, progressMessage])


  useEffect(() => {
    if (showProgressBar) {
      setProgressAnimation(actualProgress === 0||isSearchLoading ? thinkingTree : growing);
    } else {
      setProgressAnimation(undetermined);
    }
  }, [showProgressBar, actualProgress]);

  if ((!isLoading && !isSearchLoading && !treemapIsLoading && !isTopicInferenceLoading && !isClassificationLoading)) {
    return null;
  }


  return (
    <Box sx={{width: '100%'}} className={'loader-container'} style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Box sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80vw'
      }}>
        <Stack spacing={3} sx={{width: '100%', alignItems: 'center'}}>
          <Box sx={{width: '300px', height: '300px'}}>
            <Lottie animationData={progressAnimation}/>
          </Box>
          <Typography align='center' sx={{
            color: '#FFFFFF',
            fontFamily: 'Raleway',
            fontSize: '35px',
            fontWeight: '500',
            textTransform: 'uppercase'
          }}>
            {title}
            {showProgressBar && <span
              style={{color: theme.palette.primary.main}}> - {Math.min(actualProgress, 100).toFixed(1)}%</span>}
          </Typography>
          {showProgressBar && (
            <LinearProgress
              variant={(actualProgress > 0) ? "determinate" : "indeterminate"}
              value={actualProgress}
              sx={{height: '5px', width: '100%'}}/>
          )}
          <Typography align='center' color='#FFFFFF' sx={{
            fontFamily: 'Montserrat',
            fontSize: '20px',
            fontWeight: '600',
          }}>
            {currentMessage}
          </Typography>
        </Stack>
      </Box>
    </Box>
  )
}

export default ProsperiaLoader;
