// External library imports
import {Box, Container, Grid, styled, Switch} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import LinkIcon from '@mui/icons-material/Link';
import {ColorField} from 'components/common/ReactHooksFormFields';
import {SelectField, TextField} from '../../../common/ReactHooksFormFields';

export const MuiContainer = styled(Container)(({theme}) => ({
  padding: theme.spacing(0, 2, 0, 2),
  marginLeft: theme.spacing(-3),
}));

export const MuiSelectField = styled(SelectField)(({theme}) => ({
  paddingTop: '0px!important',
  paddingBottom: '0px!important',
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.5px'
  },
}));

export const MuiColorField = styled(ColorField)(({theme}) => ({
  width: '24px',
  height: '24px',
  padding: '0px',
  borderRadius: '4px',
  '& .MuiInputBase-input': {
    padding: '0px',
    height: '24px',
    width: '24px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    padding: '0px',
  },
}));

export const MuiTextField = styled(TextField)(({theme}) => ({
  paddingTop: '0px !important',
  paddingBottom: '0px !important',
  '& .MuiInputBase-root': {
    height: '56px',
    borderRadius: '5px'
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.5px'
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.4px'
  },
  '& input': {
    fontSize: '16px',
    fontFamily: 'Montserrat',
  },
  '& legend': {
    fontSize: 14
  },
}));

export const MuiGridInputText = styled(Grid)(({theme}) => ({}));

export const MuiDescriptionIcon = styled(DescriptionIcon)(({theme}) => ({
  width: '100px !important',
  height: '100px !important',
  color: localStorage.getItem('colorPrimary'),
  fontSize: '40px !important',
}));

export const MuiLinkIcon = styled(LinkIcon)(({theme}) => ({
  width: '100px !important',
  height: '100px !important',
  color: localStorage.getItem('colorPrimary'),
  fontSize: '40px !important',
}));

export const MuiGridColorPickers = styled(Grid)(({theme}) => ({
  margin: theme.spacing(0, 0),
  paddingLeft: '8px',
  display: 'flex',
  justifyItems: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const MuiGridTextContainer = styled(Grid)(({theme}) => ({
  height: 24,
}));


export const MuiSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
), {shouldForwardProp: prop => prop !== 'colorSecondary'})(({
                                                              theme,
                                                              colorSecondary
                                                            }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: colorSecondary,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const MuiTexInput = styled(TextField)(({theme}) => ({
  '& .MuiInputBase-root': {
    width: '400px',
    height: '56px',
  },
  '& .MuiInputLabel-root': {
    marginTop: '5px',
    fontSize: '16px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.5px'
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.4px'
  },
  '& input': {
    fontSize: '16px',
    fontFamily: 'Montserrat',
  },
  '& legend': {
    fontSize: '14px',
  },
}));

export const MuiGridCommon = styled(Grid)(({theme}) => ({
  margin: 'auto',
  marginBottom: 12,
}));

export const MuiTypographyPicker = styled(Grid)(({theme}) => ({
  fontFamily: "Montserrat",
  fontWeight: 400,
  fontSize: "12px",
}));

export const MuiBoxPalette = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0px',
  cursor: 'pointer',
  padding: '2px',
  backgroundColor: '#f5f5f5',
  borderRadius: '4px',
  border: '1px solid #ccc',
  width: '294px',
}));

export const colorCommonStyle = {
  color: '#254957',
  fontFamily: 'Montserrat'
};

export const gridPaddingStyle = {
  paddingTop: 16
}

export const buttonActionStyle = {
  padding: '10px 16px 10px 16px',
  borderRadius: '20px',
  color: '#005F64',
  borderColor: '#005F64',
  height: '37px'
}

export const commonStyle = {
  fontFamily: 'Montserrat',
  fontWeight: 400,
  textTransform: 'uppercase',
  fontSize: '12px'
}

export const selectRoleStyle = {
  '& .MuiInputBase-root': {
    width: '400px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '16px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.5px'
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.4px'
  }
}

export const optionLabelStyle = {
  fontFamily: 'Montserrat',
  fontSize: '16px',
  letterSpacing: 0.5
}

export const gridDividerStyle = {
  paddingTop: 0,
  marginTop: -5
}

export const fieldDescriptionStyle = {
  '& .MuiInputBase-root': {
    height: '105px',
    borderRadius: '5px'
  },
}
