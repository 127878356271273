import axios from 'axios';
import axiosRetry from "axios-retry";

const axiosGeneric = axios.create();

axiosGeneric.interceptors.request.use(config => {
  // 1. Save the original URL
  const originalUrl = config.url;

  // 2. Rewrite the request:
  //    a) Base URL or final URL will be the proxy endpoint
  //    b) The original request URL will be moved to a query parameter
  config.url = `${process.env.REACT_APP_API_URL}/proxy`;

  // 3. Attach the original URL as a `url` parameter
  config.params = {
    ...config.params,
    url: originalUrl
  };

  return config;
}, error => {
  // Forward the error if something went wrong before the request is sent
  return Promise.reject(error);
});

axiosRetry(axiosGeneric, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    const isCorsError = error.message.includes('CORS') || error.code === 'ERR_CORS';
    if (axiosRetry.isNetworkError(error)) {
      console.error('Network Error', error);
    } else if (isCorsError) {
      console.error('CORS Error', error);
    } else if (axiosRetry.isRetryableError(error)) {
      console.error('Retryable Error', error);
    } else {
      console.error('Unknown Error', error);
    }
    return (
      axiosRetry.isNetworkError(error) ||
      axiosRetry.isRetryableError(error) ||
      error.response?.status >= 500 ||
      isCorsError ||
      true
    );
  }
})

export default axiosGeneric;
