import _ from 'lodash';

export const schemeChanged = function(newScheme, oldScheme) {
  // if first time save..
  if (oldScheme === null && newScheme) {
    return true;
  }

  if (oldScheme.name !== newScheme.name) {
    return true;
  }
  if (oldScheme.weight!==newScheme.weight) {
    return true;
  }

  // collection
  if (oldScheme.collection !== newScheme.collection) {
    return true;
  }
  if (oldScheme.dataset !== newScheme.dataset) {
    return true;
  }
  if (oldScheme.description!== newScheme.description) {
    return true;
  }

  if (!_.isEqual(oldScheme.schemeCollectionConfig, newScheme.schemeCollectionConfig)) {
    return true;
  }

  if (!_.isEqual(oldScheme.segmentation_variables, newScheme.segmentation_variables)) {
    return true;
  }
  if(!_.isEqual(oldScheme.statistic_variables, newScheme.statistic_variables)) {
    return true;
  }

  if(!_.isEqual(oldScheme.hints, newScheme.hints)) {
    return true;
  }
  if(!(!_.isEqual(oldScheme.analysisObjective, newScheme.analysisObjective) ||
    !_.isEqual(oldScheme.contextDescription, newScheme.contextDescription) ||!_.isEqual(oldScheme.audienceDescription, newScheme.audienceDescription))) {
    return true;
  }
  // policies
  if (newScheme.policies.length !== oldScheme.policies.length) {
    return true;
  } else if (newScheme.policies.length === oldScheme.policies.length) {

    const diff = oldScheme.policies.filter((d, i) => {
      return !_.isEqual(d, newScheme.policies[i]);
    }).map(d => d.id);
    return diff.length ? diff : false;
  }

  return false;
};


export function generateID() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export  const getPercentage = (height, type, semanticExpanded, queryTextEntered) => {
  let heightBreakpoint, percentage;

  if (height < 600) {
    heightBreakpoint = "xs"
  } else if (height >= 600 && height < 800) {
    heightBreakpoint = "sm";
  } else if (height >= 800 && height < 1000) {
    heightBreakpoint = "md";
  } else if (height >= 1000 && height < 1200) {
    heightBreakpoint = "lg";
  } else {
    heightBreakpoint = "xl";
  }

  const ranges = {
    xs: { expanded: 35, queryText: 60, default: 70 },
    sm: { expanded: 50, queryText: 70, default: 77 },
    md: { expanded: 55, queryText: 74, default: 80 },
    lg: { expanded: 60, queryText: 74, default: 83 },
    xl: { expanded: 67, queryText: 74, default: 85 },
  };

  if (type === "similarity") {
    percentage = 52;
  } else {
    const range = ranges[heightBreakpoint];
    if (range) {
      if (semanticExpanded && queryTextEntered) {
        percentage = range.expanded;
      } else if (queryTextEntered) {
        percentage = range.queryText;
      } else {
        percentage = range.default;
      }
    }
  }
  console.log(heightBreakpoint)
  return percentage
}
