import {
  Box, IconButton,
  lighten,
  styled,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import {darken} from "@mui/material/styles";

export const MuiToggleGroup=styled(Box)(({ theme }) => ({
  height:'32px',
  borderRadius:'16px',
  padding:'4px',
  border:`1px solid ${theme.palette.primary.main}`,
  backgroundColor: lighten(theme.palette.primary.main, 0.8),
  gap:'4px',
  display:'flex',
  flexDirection:'row',
  alignItems:'center'
}))

export const MuiToggleButton=styled(IconButton,{shouldForwardProp: (prop) => prop !== 'selected'})(({ theme, selected }) => ({
  height:'24px',
  borderRadius:'12px',
  padding:'4px',
  border:`1px solid ${selected?theme.palette.primary.main:'transparent'}`,
  backgroundColor: selected ? theme.palette.primary.main : 'transparent',
  '&:hover': {
    backgroundColor: selected ? theme.palette.primary.main : 'transparent',
  },
  width:'44px'
}))

export const MuiAudioEnabledIcon=styled(VolumeUpIcon,{shouldForwardProp: (prop) => prop !== 'selected'})(({ theme, selected }) => ({
  color:selected?'#ffffff':darken(theme.palette.primary.main, 0.2),
  width:'16px',
  height:'16px'
}))

export const MuiAudioDisabledIcon=styled(VolumeOffIcon,{shouldForwardProp: (prop) => prop !== 'selected'})(({ theme, selected }) => ({
  color:selected?'#ffffff':darken(theme.palette.primary.main, 0.5),
  width:'16px',
  height:'16px'
}));
