import React, {useState} from 'react';
import Popover from '@mui/material/Popover';
import {infoIcon, MuiInfoIcon, MuiPDFIcon} from './styles/navigationMenu';
import {InfoOutlined} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {Grid, Typography, useTheme} from '@mui/material';
import {useTranslation} from "../providers/TranslationProvider";
import {
  MuiDownloadPDFButton,
  MuiIconClose,
  MuiTitleTypography
} from "./styles/infoButtonWithPopover";

const InfoButtonWithPopover = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {t} = useTranslation();
  const {scheme} = props
  const theme = useTheme();

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const formatDate = (dateStr) => {
    if (!dateStr) return null;
    const date = new Date(dateStr.trim());
    const options = {month: 'long', day: 'numeric', year: 'numeric'};
    const formattedDate = date.toLocaleDateString('en-US', options);

    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    };
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

// Combine the date and time
    return `${formattedDate} | ${formattedTime}`;
  }
  return (
    <>
      <MuiInfoIcon onClick={handleClick}/>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid container direction={'column'} alignItems={'flex-start'}
              justifyContent={'center'}
              sx={{width: '360px', padding: '24px 24px 24px 24px'}} rowGap={'14px'}>
          <Grid container item sx={{width:'100%'}} justifyContent={'flex-end'}
                alignContent={'center'}>
            <Grid container item xs={'auto'} direction='column'
                  alignContent={'flex-start'} justifyContent={'flex-start'} rowGap={'4px'}>
              <MuiTitleTypography>{scheme.name}</MuiTitleTypography>
              {
                (!!scheme?.createdAt) && (
                  <Typography sx={{
                    fontFamily: 'Montserrat',
                    fontSize:'12px',
                    color:'#616161',
                    textAlign:'left'
                  }}>
                    {t('created_date')}: {formatDate(scheme.createdAt)}
                  </Typography>
                )}
            </Grid>
            <Grid container item xs={true} sx={{height: '100%'}}
                  alignContent={'flex-start'} justifyContent={'flex-end'}>
              <MuiIconClose aria-label="close" onClick={handleClose} sx={{
                alignSelf: 'flex-start'
              }}>
                <CloseIcon sx={{width: '16px', height: '16px'}}/>
              </MuiIconClose>
            </Grid>
          </Grid>
          <Grid container item sx={{width:'100%'}} direction='column' rowGap={'14px'}>
            <Grid container item direction='column' sx={{width:'100%'}}>
              <Typography sx={{
                fontFamily:'Montserrat',
                fontWeight:600,
                fontSize:'16px',
                lineHeight:'20px',
                letterSpacing:'0.005em',
                color:'#616161'
              }}>
                {t('objective_analysis')}:
              </Typography>
              <Typography sx={{
                fontFamily:'Montserrat',
                fontSize:'14px',
                lineHeight:'20px',
                letterSpacing:'0.005em',
                color:'#616161'
              }}>
                {scheme.analysisObjective || t('not_provided')}
              </Typography>
            </Grid>
            <Grid container item direction='column' sx={{width:'100%'}}>
              <Typography sx={{
                fontFamily:'Montserrat',
                fontWeight:600,
                fontSize:'16px',
                lineHeight:'20px',
                letterSpacing:'0.005em',
                color:'#616161'
              }}>
                {t('population_content')}:
              </Typography>
              <Typography sx={{
                fontFamily:'Montserrat',
                fontSize:'14px',
                lineHeight:'20px',
                letterSpacing:'0.005em',
                color:'#616161'
              }}>
                {scheme.contextDescription || t('not_provided')}
              </Typography>
            </Grid>
            <Grid container item direction='column' sx={{width:'100%'}}>
              <Typography sx={{
                fontFamily:'Montserrat',
                fontWeight:600,
                fontSize:'16px',
                lineHeight:'20px',
                letterSpacing:'0.005em',
                color:'#616161'
              }}>
                {t('audience_description')}:
              </Typography>
              <Typography sx={{
                fontFamily:'Montserrat',
                fontSize:'14px',
                lineHeight:'20px',
                letterSpacing:'0.005em',
                color:'#616161'
              }}>
                {scheme.audienceDescription || t('not_provided')}
              </Typography>
              <MuiDownloadPDFButton
                onClick={() => {
                  const fileUrl = scheme.reportUrl;
                  const link = document.createElement('a');
                  link.href = fileUrl; // Set the file URL
                  link.download = `${scheme.name}.pdf`; // Set the filename to download
                  link.target = '_blank'; // Optional, ensures the link opens in a new tab
                  document.body.appendChild(link); // Append the link to the DOM
                  link.click(); // Trigger the click to download
                  document.body.removeChild(link);
                }}
                variant='outlined'
                startIcon={<MuiPDFIcon sx={{
                  width: 12,
                  height: 15,
                  '& path': {
                    fill: theme.palette.primary.main,
                  }
                }}/>}
              >
                {t('download_report')}
              </MuiDownloadPDFButton>
              <Typography sx={{
                fontFamily:'Montserrat',
                fontSize:'10px',
                lineHeight:'12px',
                fontWeight:400,
                color:'#616161',
                marginTop:'8px',
                textTransform:'uppercase'
              }}>
                {`${t('last_update')}: `}{scheme.reportUpdateDate}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default InfoButtonWithPopover;
