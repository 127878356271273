import {
  Grid,
  IconButton,
  Popover,
  ToggleButton,
  ToggleButtonGroup, Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from '../../providers/TranslationProvider';
import CloseIcon from '@mui/icons-material/Close';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import {
  MuiAudioDisabledIcon,
  MuiAudioEnabledIcon, MuiToggleButton,
  MuiToggleGroup
} from "./styles/listFilterMenu";
import {useDispatch, useSelector} from "react-redux";
import {setListFilter} from "../../../store/appSlice";

const ListFilterMenu = ({
                          showBinsSlider,
                          setShowBinSlider,
                          position,
                          policyId
                        }) => {

  const {t} = useTranslation();
  const listFilter = useSelector(state => state.app.listFilter)
  const dispatch = useDispatch()
  const [enabled, setEnabled] = useState(!listFilter[policyId] || listFilter[policyId] === 'audio_only' || listFilter[policyId] === 'all'); // Audio enabled state
  const [disabled, setDisabled] = useState(!listFilter[policyId] || listFilter[policyId] === 'text_only' || listFilter[policyId] === 'all'); // Audio disabled state

  const handleButtonClick = (button) => {
    if (button === 'enabled') {
      if (listFilter[policyId] === 'audio_only' || listFilter[policyId] === 'all') {
        dispatch(setListFilter({id: policyId, filter: 'text_only'}));
      } else {
        dispatch(setListFilter({id: policyId, filter: 'all'}));
      }
    } else if (button === 'disabled') {
      if (listFilter[policyId] === 'text_only' || listFilter[policyId] === 'all') {
        dispatch(setListFilter({id: policyId, filter: 'audio_only'}));
      } else {
        dispatch(setListFilter({id: policyId, filter: 'all'}));
      }
    }
  };


  useEffect(() => {
    const currentFilter = listFilter[policyId];
    if (!currentFilter) {
      dispatch(setListFilter({id: policyId, filter: 'all'}));
    }
    if (currentFilter === 'audio_only') {
      setEnabled(true);
      setDisabled(false);
    } else if (currentFilter === 'text_only') {
      setEnabled(false);
      setDisabled(true);
    } else {
      setEnabled(true);
      setDisabled(true);
    }
  }, [listFilter])

  const handleClose = () => setShowBinSlider(false)


  return (
    <Popover
      open={showBinsSlider}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position.y + 40,
        left: position.x + 32
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        overflowY: 'hidden',
        borderRadius: '8px'
      }}
    >
      <Grid
        container
        direction={'column'}
        alignItems={'center'}
        sx={{
          width: '158px',
          padding: '8px 12px'
        }}
        rowGap={'8px'}
      >
        <Grid container item xs={true} direction={'row'}>
          <Grid item xs={true}>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              fontWeight: 600,
              fontStyle: 'normal',
              textAlign: 'left',
              letterSpacing: '0.005em',
              lineHeight: '17px',
              color: '#424242',
            }}>Mostrar citas:</Typography>
          </Grid>
          <Grid container item xs={'auto'} justifyContent={'flex-end'}
                alignItems={'flex-start'}>
            <IconButton aria-label="close" onClick={handleClose}
                        sx={{width: '16px', height: '16px'}}>
              <CloseIcon sx={{width: '11px', height: '11px'}}/>
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item xs={true} sx={{width: '100%'}}
              justifyContent={'flex-start'}>
          <MuiToggleGroup>
            <MuiToggleButton selected={enabled}
                             onClick={() => handleButtonClick('enabled')}>
              <MuiAudioEnabledIcon selected={enabled}/>
            </MuiToggleButton>
            <MuiToggleButton selected={disabled}
                             onClick={() => handleButtonClick('disabled')}>
              <MuiAudioDisabledIcon selected={disabled}/>
            </MuiToggleButton>
          </MuiToggleGroup>
        </Grid>
      </Grid>
    </Popover>
  )
}

export default ListFilterMenu
