import {Button, IconButton, styled, Typography} from "@mui/material";

export const MuiTitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontStyle:'normal',
  fontWeight: 700,
  textAlign: 'left',
  color:'#424242',
  letterSpacing:'0.0015em',
  lineHeight:'25px',
  fontSize:'21px',
}));

export const MuiIconClose= styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#B2EBF2'
  }
}));

export const MuiDownloadPDFButton = styled(Button)(({ theme }) => ({
  padding:'4px 12px 4px 8px',
  fontFamily: 'Montserrat',
  fontSize: '10px',
  borderColor: 'primary.main',
  borderRadius: '13px',
  height: '26px',
  width: '160px',
  textTransform: 'uppercase',
  marginTop:'14px'
}));
