import React from 'react';
import { Pie } from '@nivo/pie';
import {getContrastTonality} from "../../../../../../utils/colorPaletteUtils";

const PieChart = ({data}) => {
  return (
      <Pie
        width={400}
        height={130}
        data={data}
        animate={false}
        margin={{ top: 0, right: 0, bottom: 0, left: -250 }}
        innerRadius={0.6}
        padAngle={1}
        cornerRadius={4}
        colors={({ data }) => data.color}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              '0'
            ]
          ]
        }}
        enableArcLinkLabels={false}
        arcLabel={e=>`${e.value}%`}
        arcLabelsTextColor={({ data }) => getContrastTonality(data.color) === 'dark' ? '#000000' : '#FFFFFF'}
        isInteractive={false}
        legends={[
          {
            anchor: 'right',
            direction: 'column',
            justify: false,
            translateX: -250,
            translateY: 0,
            itemWidth: 0,
            itemHeight: 10,
            itemsSpacing: 7,
            symbolSize: 10,
            itemDirection: 'left-to-right'
          }
        ]}
        theme={{
          legends: {
            text: {
              fontSize: 10,
              fontFamily: 'Montserrat',
              fill: '#000000',
            },
          },
          axis: {
            legend: {
              text: {
                fontFamily: 'Montserrat',
                fontSize: 10,
                fill: '#000000',
              },
            },
          },
          labels: {
            text: {
              fontSize: 10,
              fontFamily: 'Montserrat',
            },
          },
          fontFamily: 'Montserrat',
          fontSize: 10
        }}
      />
  );
};

export default PieChart;
