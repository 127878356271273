import axios from 'axios';
import axiosRetry from "axios-retry";

const baseUrl = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

axiosRetry(axiosInstance,{
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    return (
      axiosRetry.isNetworkError(error) ||
      axiosRetry.isRetryableError(error) ||
      error.response?.status >= 500
    );
  }
})

export default axiosInstance;
