import color from "color";
import * as React from "react";

const CustomLegend = ({
                        data,
                        label,
                        width,
                        referenceKey,
                        updateReferenceKey,
                        colorPalette
                      }) => {
  const itemWidth = (data.reduce((acc, item) => Math.max(acc, `${item}`.length), 0) * 8) + 40; // Set the width for each legend item
  const itemsPerRow = Math.floor(width / itemWidth);
  const isNarrow = width < 400; // Adjust this value based on your needs
  // Map data to include the index
  const indexedData = data.map((item, index) => ({item, index}));

  const rows = indexedData.reduce((acc, {item, index}) => {
    const rowIndex = Math.floor(index / itemsPerRow);
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push({item, index});
    return acc;
  }, []);

  return (
    <div
      style={{
        width: width,
        paddingLeft: '20px',
        marginTop: '10px',
        maxWidth:width,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection:  isNarrow ? 'column' : 'row',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
        }}>
        <div style={{
          // display: 'flex',
          // alignItems: 'center',
          marginRight: '20px',
        }}>
          <span style={{
            fontFamily: 'Montserrat',
            fontSize: '12px',
            fontWeight: '600',
            fontStyle: 'normal',
            letterSpacing: '0.015em',
            textTransform: 'uppercase',
            color: '#424242',
            marginTop:'0px',
            textAlign:'left',
            textWrap:'nowrap'
          }}>
            {label}:
          </span>
        </div>

        {/* Column for the legend items */}
        <div style={{display: 'flex', flexDirection: 'column',marginTop:'3px'}}>
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} style={{
              display: 'flex',
              flexDirection: 'row',
              maxWidth:'100%',
              whiteSpace:'pre-wrap',
              wordBreak:'break-word',
              justifyContent: 'flex-start',
              marginBottom: '10px'
            }}>
              {row.map(({item, index}) => {
                const isReferenceKey = item === referenceKey;
                const itemColor = colorPalette[item];
                const borderColor = color(itemColor).darken(0.2).hex();
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: item.length * 8 + 40,
                      whiteSpace: 'pre-wrap', // Enable multiline text
                    }}
                  >
                <span
                  onClick={() => updateReferenceKey(referenceKey!==item?item:'value')}
                  style={{
                    width: isReferenceKey ? '15.5px' : '15px',
                    height: isReferenceKey ? '15.5px' : '15px',
                    backgroundColor: isReferenceKey ? color(itemColor).lighten(0.2).hex() : itemColor,
                    marginRight: '5px',
                    cursor: 'pointer',
                    border: isReferenceKey ? `3px solid ${borderColor}` : 'none',
                    borderRadius: '4px',
                    boxShadow: isReferenceKey ? '0px 4px 8px 4px rgba(0, 0, 0, 0.1)' : 'none',
                  }}
                />
                    <span style={{
                      fontFamily: 'Montserrat',
                      fontSize: '12px'
                    }}>{item}</span>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomLegend;
