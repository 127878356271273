import * as React from 'react';
import {Grid, TableContainer} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import useSchemes from 'api/hooks/useSchemes';
import {usePermissions} from 'components/hooks/usePermissions';
import {useTranslation} from 'components/providers/TranslationProvider';
import TPagination from '../common/TPagination';

import Table from '../common/Table';
import {useAuth} from 'components/providers/AuthProvider';

import NewScenario from './NewScenario';
import DeleteScenario from './DeleteScenario';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import CopySchemesModal from './CopySchemesModal';
import {
  MuiButton,
  MuiContainer,
  MuiGridButtonsContainer,
  MuiGridTitle,
  MuiTypographyTitle
} from '../styles/scenario';
import TabsCustoms from '../common/TabsCustoms';

const columns = ['Name', 'Created', 'Actions'];

const row = ['name', 'createdAt'];

const Scenarios = () => {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState(new Array(columns.length).fill(''));
  const [dateRange, setDateRange] = React.useState('')
  const [sortedData , setSortedData ] = React.useState([]);

  const { getRefreshToken } = useAuth();
  const location = useLocation();
  const { user } = useAuth();
  const Id =
    location.pathname.split('/')[2] === 'workspace' ? user.id : location.pathname.split('/')[2];
  const { data, isLoading } = useSchemes(Id);
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();

  const query = new URLSearchParams(location.search);
  const { t } = useTranslation();

  const handleDelete = (id, scheme) =>
    navigate(`${location.pathname}?delete=${id}`, { replace: true, state: scheme });

  const { REACT_APP_VUE_APP_URL } = process.env;

  const scenarioUrlOld = `${REACT_APP_VUE_APP_URL}/${Id}?token=${getRefreshToken()}`;


  const handleForward = (id, view) => {
    if (view === 'new') {
      navigate(`${location.pathname}/${id}/analysis`);
    } else {
      window.location.href = `${REACT_APP_VUE_APP_URL}/${Id}/analysis/${id}?token=${getRefreshToken()}`;
    }
  };

  const handleSettings = (id, scheme) =>
    navigate(`${location.pathname}/${id}/specification`, { state: scheme });

  const handleCopyScheme = () =>
    navigate(`${location.pathname}?copy=map`, {
      replace: true,
    });

  React.useEffect(() => {
    const createQuery = query.get('copy');
    setOpen(createQuery === 'map');
  }, [query]);

  const handleClose = () => {
    query.delete('create');
    navigate(`${location.pathname}${query && ''}`, { replace: true });
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const filteredData = sortedData.filter((item) => {
      let matchesSearchTerm = true;
      let matchesDateRange = true;

      if (dateRange !== '' && item.createdAt !== '') {
        const [date] = item.createdAt.split(' ');
        const [itemDay, itemMonth, itemYear] = date.split('/').map(Number);
        const [initDay, initMonth, initYear] = dateRange.initDate.split('/').map(Number);
        const [endDay, endMonth, endYear] = dateRange.endDate.split('/').map(Number);

        const itemDate = new Date(itemYear, itemMonth - 1, itemDay);
        const startDate = new Date(initYear, initMonth - 1, initDay);
        const endDate = new Date(endYear, endMonth - 1, endDay);

        matchesDateRange = itemDate >= startDate && itemDate <= endDate;
      }else if (dateRange !== '' && item.createdAt === ''){
        return false;
      }

      const lowerCaseSearchTerm = searchTerm.map((term) => term.toLowerCase());
      const itemString = `${item.name} ${item.createdAt}`.toLowerCase();
      matchesSearchTerm = lowerCaseSearchTerm.every((term) => itemString.includes(term));
      return matchesSearchTerm && matchesDateRange;
    });

  const offset = page * rowsPerPage;
  const currentPageData = filteredData.slice(offset, offset + rowsPerPage);

  const handlePreviousPage = () =>
    setPage((prevPage) => Math.max(0, prevPage - 1));


  const handleNextPage = () =>
    setPage((prevPage) => Math.min(Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1, prevPage + 1));


  const handleFirstPage = () => setPage(0);

  const handleLastPage = () => {
    const lastPage = Math.max(0, Math.ceil((filteredData?.length || 0) / rowsPerPage) - 1);
    setPage(lastPage);
  };

  const handleSearch = (event, index) => {
    const newSearchTerms = [...searchTerm];
    newSearchTerms[index] = event.target.value;
    setSearchTerm(newSearchTerms);
    setPage(0);
  };

  const toDate = (dateStr) => {
    const [date, time] = dateStr.split(' ');
    const [day, month, year] = date.split('/').map(num => parseInt(num, 10));
    const [hours, minutes, seconds] = time.split(':').map(num => parseInt(num, 10));
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  React.useEffect(() => {
    if (data && data?.schemes) {
      const orderedData = [...data.schemes].sort((a, b) => {
        const isFirstDateEmpty = !a?.createdAt?.trim();
        const isSecondDateEmpty = !b?.createdAt?.trim();

        if (isFirstDateEmpty && !isSecondDateEmpty) {
          return 1;
        } else if (!isFirstDateEmpty && isSecondDateEmpty) {
          return -1;
        }else if (isFirstDateEmpty && isSecondDateEmpty) {
          return 0;
        }

        const firstItem = toDate(a.createdAt)
        const secondItem = toDate(b.createdAt);

        return secondItem - firstItem;
      });
      setSortedData(orderedData);
    } else setSortedData([]);
  }, [data]);

  return (
    <MuiContainer>
      <TabsCustoms/>
      <MuiGridButtonsContainer container direction='row' sx={{width:'100%'}} justifyContent={'flex-start'} id={'scenarios-button-container'}>
        <MuiGridTitle container item xs={true} justifyContent={'flex-start'} sx={{minWidth:'117px'}}>
          <MuiTypographyTitle variant='subtitle1'>
            {t('analysis_list')}
          </MuiTypographyTitle>
        </MuiGridTitle>
        <Grid container item xs={7} sx={{ paddingRight:'150px',flexWrap:'nowrap',minWidth:'625px'}} justifyContent={'flex-start'} alignContent={'center'}>
          {hasPermissionFor('update_schemes') && (<MuiButton
              variant='outlined'
              color='primary'
              startIcon={<FileCopyIcon />}
              onClick={handleCopyScheme}
            >
              {t('copy_schemes')}
            </MuiButton>
          )}
          {hasPermissionFor('create_schemes') && <NewScenario urlBase={scenarioUrlOld} />}
        </Grid>
      </MuiGridButtonsContainer>
      {/* <UpdateScenario /> */}
      <DeleteScenario />
      <TableContainer  sx={{background:'#FAFAFA'}}>
        <Table
          handleSearch={handleSearch}
          setDateRange={setDateRange}
          searchTerm={searchTerm}
          data={currentPageData}
          row={row}
          columns={columns}
          onDelete={handleDelete}
          showDelete={hasPermissionFor('delete_schemes')}
          onSettings={handleSettings}
          showSettings={hasPermissionFor('update_schemes')}
          onForward={handleForward}
          isLoading={isLoading}
        />
      </TableContainer>
      <TPagination
        data={filteredData}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleFirstPage={handleFirstPage}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        handleLastPage={handleLastPage}
      />
      {open && <CopySchemesModal action='create' open={open} onClose={handleClose} />}
    </MuiContainer>
  );
};

export default Scenarios;
