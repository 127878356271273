import {Button, Container, Grid, styled, Typography} from '@mui/material';

export const MuiButtonSpacing = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(4),
  justifySelf: 'end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: '#616161',
  borderColor: '#BDBDBD',
  padding:'10px 24px 10px 16px',
  height:'32px'
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  justifySelf: 'end',
  borderRadius:'16px',
  fontFamily:'Montserrat',
  fontSize:14,
  color: '#616161',
  borderColor: '#BDBDBD',
  padding:'10px 24px 10px 16px',
  height:'32px'
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(0),
  paddingTop: theme.spacing(0.4),
  paddingBottom: theme.spacing(3),
  alignItems: 'left',
  backgroundColor:'#FAFAFA',
  borderRadius:'28px',
  '& .MuiContainer-root': {
    maxWidth: '1360px !important',
  },
}));

export const MuiDivContainer = styled("div")(({ theme }) => ({
  height: '94vh',
  position: 'relative',
  left: '-60px',
}));

export const MuiGridButtonsContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  // margin: theme.spacing(0, 2, 2, 2),
  // justifyContent: 'flex-end',
}));

export const MuiGridTitle = styled(Grid)(({ theme }) => ({
  paddingLeft:'0px !important',
  paddingTop:'0px !important'
}));

export const MuiTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize:21,
  fontFamily:'Raleway',
  textAlign:'left',
  fontWeight:500
}));
