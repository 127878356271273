import * as React from 'react';
import {useEffect} from 'react';
import {
  Box,
  Button as MuiButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  ThemeProvider,
  Typography
} from '@mui/material';
import * as yup from 'yup';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useTranslation} from 'components/providers/TranslationProvider';
import Toast from 'components/common/Toast';
import themeCrowdView from 'theme/crowdView';
import Modal from 'components/common/Modal';
import {
  labelStepStyle,
  MuiBoxContainer,
  MuiBtnNext,
  MuiCustomStepIcon,
  MuiGridContainerStepper,
  StepperContainer
} from "./sections/steps/styles/question";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  gridPaddingStyle,
  MuiColorField,
  MuiGridColorPickers,
  MuiGridTextContainer,
  MuiSwitch,
  MuiTypographyPicker
} from "../../styles/formModal";
import {MuiBackupIcon} from "./styles/configurationSteps";
import {useTheme} from "../../../../providers/CustomThemeProvider";
import {useSelector} from "react-redux";
import {
  iconStyle,
  labelStyle,
  MuiBoxFile,
  MuiButtonSelectedFile,
  MuiCharCountText, MuiCharCountTitle,
  MuiDescription,
  MuiDescriptionField,
  MuiErrorText,
  MuiFileName,
  MuiFileUploadedText,
  MuiLabel,
  MuiName,
  MuiSubTitle,
  MuiTitle, MuiTitleField,
  stepperStyle
} from "./styles/generateReportModal";
import {exportPDF} from "../exporting/exportToPDF";
import axiosEngineInstance from "../../../../../api/axios/axiosEngineInstance";
import {
  generateScaledPalette,
  getContrastTonality
} from "../../../../../utils/colorPaletteUtils";
import useOrganization from "../../../../../api/hooks/useOrganization";
import {useAuth} from "../../../../providers/AuthProvider";
import {status200} from "../../../../../api/status.utils";
import {useLocation} from "react-router-dom";
import {useLoading} from "../../../../providers/LoadingProvider";
import {normalizeString} from "../../../../../utils/text";
import axiosInstance from "../../../../../api/axios/axiosInstance";

const schema = yup.object().shape({
  title: yup.string(),
  description: yup.string(),
  primaryColor: yup.string(),
  secondaryColor: yup.string(),
});

const GeneralInformation = ({
                              methods, handleTextChange, charCount, fileName,
                              handleDrop, handleDragOver, handleFileSelect,
                              error, t, theme, handleTitleLengthChange,charTitleCount
                            }) => {

  const maxChars = 750;
  const maxCharsTitle = 100;

  return (
    <Grid container spacing={2}>
      <Grid item>
        <MuiTitle>
          {t('title_report')}
        </MuiTitle>
      </Grid>
      <Grid container item justifyContent='center' sx={{ position: 'relative' }}>
        <MuiTitleField
          id="outlined-multiline-static"
          name='title'
          label={t('custom_title')}
          variant='outlined'
          InputLabelProps={{
            shrink: true,
            style: labelStyle
          }}
          inputProps={{maxLength: maxCharsTitle}}
          {...methods?.register('title')}
          onChange={handleTitleLengthChange}
        />
        <MuiCharCountTitle>
          <span style={{color: '#7a7a7a'}}>{`${charTitleCount}/`}</span>
          <span style={{color: '#000000'}}>{maxCharsTitle}</span>
        </MuiCharCountTitle>
      </Grid>
      <Grid item>
        <MuiTitle sx={{marginTop:'0px !important'}}>
          {t('extended_description')}
        </MuiTitle>
      </Grid>
      <Grid container item justifyContent='center' sx={{ position: 'relative' }}>
        <MuiDescriptionField
          id="outlined-multiline-static"
          name='description'
          label={t('description')}
          multiline
          rows={7}
          variant='outlined'
          InputLabelProps={{
            shrink: true,
            style: labelStyle
          }}
          inputProps={{maxLength: maxChars}}
          {...methods?.register('description')}
          onChange={handleTextChange}
        />
        <MuiCharCountText>
          <span style={{color: '#7a7a7a'}}>{`${charCount}/`}</span>
          <span style={{color: '#000000'}}>{maxChars}</span>
        </MuiCharCountText>
      </Grid>
      <Grid container item display={'flex'} justifyContent={'space-between'}>
        <Grid item>
          <MuiTitle sx={{marginTop: '0px !important'}}>
            {t('image_organization')}
          </MuiTitle>
          <MuiSubTitle>
            {t('representative_image')}
          </MuiSubTitle>
          <MuiBoxFile onDrop={handleDrop} onDragOver={handleDragOver}>
            <Grid container>
              <Grid item xs={8}>
                <MuiFileName
                  variant="body2"
                  sx={{mt: fileName ? '-12px' : '0px'}}
                >
                  <MuiBackupIcon sx={iconStyle}/>
                  {t('select_file_drag_here')}
                </MuiFileName>
                {fileName && (
                  <MuiFileUploadedText variant='caption' sx={{marginLeft: -16}}>
                    <strong>{t('file_uploaded')}: </strong>
                    {fileName.length > 30 ? `${fileName.slice(0, 30)}...` : fileName}
                  </MuiFileUploadedText>
                )}
              </Grid>
              <Grid item xs={4}>
                <MuiButtonSelectedFile
                  variant="outlined"
                  component="label"
                  sx={{
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main
                  }}
                >
                  {t('select_file')}
                  <input
                    type="file"
                    hidden
                    accept=".jpg, .jpeg, .png"
                    onChange={handleFileSelect}
                  />
                </MuiButtonSelectedFile>
              </Grid>
            </Grid>
            {error && (
              <MuiErrorText>
                {error}
              </MuiErrorText>
            )}
          </MuiBoxFile>
        </Grid>
        <Grid item>
          <MuiTitle sx={{marginTop: '0px !important'}}>
            {t('colors')}
          </MuiTitle>
          <MuiSubTitle>
            {t('select_colors_report')}
          </MuiSubTitle>
          <Grid container item display={'flex'}
                justifyContent={'space-between'}>
            <Grid style={gridPaddingStyle} item>
              <MuiGridColorPickers item xs={12}>
                <MuiGridTextContainer
                  container
                  alignContent='center'
                  justifyContent='flex-start'
                  item xs={10}
                >
                  <MuiTypographyPicker variant='caption' sx={{mr: '21px'}}>
                    {t('primary_color')}
                  </MuiTypographyPicker>
                </MuiGridTextContainer>
                <Grid item xs={2}>
                  <MuiColorField
                    name='primaryColor'
                    fullWidth
                    data-cy='primaryColor'
                  />
                </Grid>
              </MuiGridColorPickers>
            </Grid>
            <Grid style={gridPaddingStyle} item>
              <MuiGridColorPickers item xs={12}>
                <MuiGridTextContainer
                  container
                  alignContent='center'
                  justifyContent='flex-end'
                  item
                  xs={10}
                >
                  <MuiTypographyPicker variant='caption' sx={{mr: '21px'}}>
                    {t('secondary_color')}
                  </MuiTypographyPicker>
                </MuiGridTextContainer>
                <Grid item xs={2}>
                  <MuiColorField
                    name='secondaryColor'
                    data-cy='secondaryColor'
                  />
                </Grid>
              </MuiGridColorPickers>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Questions = ({
                     selectedAll, handleSwitchQuestionsChange, t, theme,
                     questionsCheckboxes, handleCheckboxQuestionChange
                   }) => {

  useEffect(() => {
    const allChecked = questionsCheckboxes.every(({checked}) => checked);
    if (selectedAll !== allChecked) {
      handleSwitchQuestionsChange(allChecked);
    }
  }, [questionsCheckboxes]);

  return (
    <Grid container spacing={2}>
      <Grid item>
        <MuiTitle>
          {t('select_questions')}
        </MuiTitle>
      </Grid>
      <Grid container alignContent='left' item>
        <MuiSwitch
          colorSecondary={theme.palette.primary.main}
          checked={selectedAll}
          onChange={handleSwitchQuestionsChange}
        />
        <MuiLabel variant='body2'>
          {t('select_all')}
        </MuiLabel>
      </Grid>
      <Grid item>
        <FormGroup>
          {questionsCheckboxes.map(({id, checked, name, longName}) => (
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => handleCheckboxQuestionChange(id)}
                  sx={{
                    color: '#9E9E9E',
                    '&.Mui-checked': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              }
              label={
                <div>
                  <MuiName variant="body1">
                    {name}
                  </MuiName>
                  <MuiDescription variant="body2">
                    {longName}
                  </MuiDescription>
                </div>
              }
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  )
}

const SegmentsVariables = ({
                             selectedSegmentsAll,
                             handleSwitchSegmentsChange,
                             t,
                             theme,
                             segmentsCheckboxes,
                             handleCheckboxSegmentsChange
                           }) => {

  useEffect(() => {
    const allChecked = segmentsCheckboxes.every((checkbox) => checkbox.checked);
    if (selectedSegmentsAll !== allChecked) {
      handleSwitchSegmentsChange(allChecked);
    }
  }, [segmentsCheckboxes]);


  return (
    <Grid container spacing={2}>
      <Grid item>
        <MuiTitle>
          {t('select_segments_variables')}
        </MuiTitle>
      </Grid>
      <Grid container alignContent='left' item>
        <MuiSwitch
          colorSecondary={theme.palette.primary.main}
          checked={selectedSegmentsAll}
          onChange={handleSwitchSegmentsChange}
        />
        <MuiLabel variant='body2'>
          {t('select_all')}
        </MuiLabel>
      </Grid>
      <Grid item>
        <FormGroup>
          {segmentsCheckboxes.map(({id, checked, description, label}) => (
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => handleCheckboxSegmentsChange(id)}
                  sx={{
                    color: '#9E9E9E',
                    '&.Mui-checked': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              }
              label={
                <div>
                  <MuiName variant="body1">
                    {label}
                  </MuiName>
                  <MuiDescription variant="body2">
                    {description}
                  </MuiDescription>
                </div>
              }
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  )
}

const GenerateReportModal = ({
                               action,
                               open,
                               onClose,
                               scheme,
                               updateScheme,
                               collection
                             }) => {

  const questions = scheme?.policies || [];
  const segmentsVariables = collection.variables;
  const [schemeModified, setSchemeModified] = React.useState(null);
  const [schemeColor, setSchemeColor] = React.useState(false);

  const arrayQuestions = questions.map(({id, name, longName}) => ({
    id,
    name,
    longName,
    checked: true
  }));
  const arraySegmentsVariables =
    segmentsVariables.filter((v) => v.category === 'categorical'&&!!v.description)
      .map(({propName, label, description}, index) => (
        {
          id: index,
          propName,
          label,
          description,
          checked: true
        }
      ))

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [fileName, setFileName] = React.useState('');
  const [fileContent, setFileContent] = React.useState('');
  const [charCount, setCharCount] = React.useState(0);
  const [charTitleCount, setCharTitleCount] = React.useState(0);
  const [selectedAll, setSelectedAll] = React.useState(true);
  const [selectedSegmentsAll, setSelectedSegmentsAll] = React.useState(true);
  const [questionsCheckboxes, setQuestionsCheckboxes] = React.useState(arrayQuestions);
  const [segmentsCheckboxes, setSegmentsCheckboxes] = React.useState(arraySegmentsVariables);
  const [activeStep, setActiveStep] = React.useState(0);
  const selectedPallete = useSelector(state => state.app.treeMapColorScheme);
  const palette = generateScaledPalette(selectedPallete, 10);
  const {
    setIsLoading,
    setProgress,
    setProgressMessage,
    setShowProgressBar,
    setTitle
  } = useLoading();
  const {user} = useAuth();
  const location = useLocation();
  const orgId =
    location.pathname.split('/')[2] === 'workspace' ? user.org_id : location.pathname.split('/')[2];
  const {data: organization} = useOrganization(orgId);

  const {t, lng} = useTranslation();
  const {theme} = useTheme();

  const getDefaultValues = () => {
    return {
      title: scheme?.name || '',
      description: organization?.attrs?.description || '',
      primaryColor: organization?.attrs?.primary_color || '#00BBD4',
      secondaryColor: organization?.attrs?.secondary_color || '#005F64',
    };
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  useEffect(() => {
    setCharCount((organization?.attrs?.description || '').length);
  }, [organization]);
  const {handleSubmit} = methods;

  const handleCloseToast = () => setOpenToast(false);

  const handleFileUpload = (file) => {
    if (!file) return;

    const fileData = new FormData();
    fileData.append('image_file', file);

    const reader = new FileReader();
    reader.onload = () => {
      setFileName(file.name);
      setFileContent(file);
    };
    reader.onerror = () => {
      setError('Error al leer el archivo');
    };
    reader.readAsText(file);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setError('');
    const file = event.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleDragOver = (event) => event.preventDefault();

  const handleFileSelect = (event) => {
    setError('');
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const handleSwitchQuestionsChange = () => {
    if (!selectedAll) {
      setQuestionsCheckboxes((prev) =>
        prev.map((checkbox) => ({...checkbox, checked: !selectedAll}))
      );
    }
    setSelectedAll((prev) => !prev);
  };

  const handleSwitchSegmentsChange = () => {
    if (!selectedSegmentsAll) {
      setSegmentsCheckboxes((prev) =>
        prev.map((checkbox) => ({...checkbox, checked: !selectedSegmentsAll}))
      );
    }
    setSelectedSegmentsAll((prev) => !prev);
  };

  const handleCheckboxQuestionChange = (id) => {
    setQuestionsCheckboxes((prev) =>
      prev.map((checkbox) =>
        checkbox.id === id ? {
          ...checkbox,
          checked: !checkbox.checked
        } : checkbox
      )
    );
  };

  const handleCheckboxSegmentsChange = (id) => {
    setSegmentsCheckboxes((prev) =>
      prev.map((checkbox) =>
        checkbox.id === id ? {
          ...checkbox,
          checked: !checkbox.checked
        } : checkbox
      )
    );
  };

  const handleTextChange = (event) => setCharCount(event.target.value.length);

  const handleTitleLengthChange = (event) => setCharTitleCount(event.target.value.length);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step) => () => setActiveStep(step);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  React.useEffect(() => {
    const convertIcons = async (color) => {
      const clonedSchema = JSON.parse(JSON.stringify(scheme));
      const updatedSchema = await updateSchemaWithPngIcons(clonedSchema, color);
      if (color === 'gray') {
        setSchemeModified(updatedSchema);
      } else {
        setSchemeColor(updatedSchema);
      }
    };
    if (scheme.policies.length > 0) {
      convertIcons('gray');
      const paletteRev = palette.reverse();
      convertIcons(paletteRev);
    }
  }, [scheme]);

  const updateSchemaWithPngIcons = async (schema, color) => {
    for (const policy of schema.policies) {
      for (const [index, topic] of policy.topics.entries()) {
        if (topic.icon) {
          topic.icon = await convertSvgToPngBase64(topic.icon, color === 'gray' ? '#616161' : getContrastTonality(palette[index]) === 'dark' ? '#212121' : '#FFFFFF');
        }
      }
    }
    return schema;
  };

  const convertSvgToPngBase64 = async (svgBase64, color, width = 32, height = 32) => {
    return new Promise((resolve, reject) => {
      const svgText = atob(svgBase64);
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(svgText, "image/svg+xml");
      const svgElement = svgDocument.documentElement;
      const styles = svgElement.querySelectorAll("style");
      styles.forEach((style) => style.remove());
      const elementsToFill = svgElement.querySelectorAll("path, rect, circle, ellipse, polygon, line, polyline");
      elementsToFill.forEach((el) => {
        el.setAttribute("fill", color);
      });
      const serializer = new XMLSerializer();
      const updatedSvgText = serializer.serializeToString(svgElement);
      const updatedSvgBase64 = btoa(updatedSvgText);
      const img = new Image();
      img.src = `data:image/svg+xml;base64,${updatedSvgBase64}`;
      img.onload = () => {
        const originalWidth = img.naturalWidth;
        const originalHeight = img.naturalHeight;
        const aspectRatio = originalWidth / originalHeight;

        // Ajustar las dimensiones del canvas manteniendo la proporción
        let canvasWidth = width;
        let canvasHeight = height;

        if (aspectRatio > 1) {
          // Imagen más ancha que alta
          canvasHeight = canvasWidth / aspectRatio;
        } else {
          // Imagen más alta que ancha
          canvasWidth = canvasHeight * aspectRatio;
        }
        // Crear y configurar el canvas
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        // Centrar la imagen en el canvas
        const offsetX = (width - canvasWidth) / 2;
        const offsetY = (height - canvasHeight) / 2;
        // Dibujar la imagen ajustada
        ctx.drawImage(img, offsetX, offsetY, canvasWidth, canvasHeight);
        // Convertir a Base64 PNG
        let pngBase64 = canvas.toDataURL("image/png");
        pngBase64 = pngBase64.replace(/^data:image\/[a-zA-Z]+;base64,/, "");
        resolve(pngBase64);
      };
      img.onerror = (err) => reject(err);
    });
  };

  const newReport = async (data) => {
    const primary_color = data.primaryColor
    const secondary_color = data.secondaryColor
    const description = data.description
    const image = fileContent
    const questions = questionsCheckboxes.filter((item) => item.checked === true).map(item => item.id);
    const segments_variables = segmentsCheckboxes.filter((item) => item.checked === true);
    setIsLoading(true);
    setTitle("Generating your report");
    setShowProgressBar(true);
    setProgress(10);
    setProgressMessage("Compiling the analysis insights for you...");
    const payload = {
      'surveyId': scheme.dataset,
      'lang': lng,
      'weight': scheme.weight,
      'region': collection.region,
      'format': 'structured',
      'stakeholdersDescription': scheme.audienceDescription,
      'contextDescription': scheme.contextDescription,
      'analysisObjective': scheme.analysisObjective,
      'segmentationVariables': segments_variables,
      'openQuestionDefinitions': scheme.policies.filter(p => questions.includes(p.id)).map((policy) => ({
        'question_text': policy.longName,
        'parentQuestion': policy.parentPolicy ? {
          'question_text': policy.parentPolicy.longName,
          'attribute': policy.parentPolicy.answerVar
        } : null,
        'attribute': policy.answerVar,
        'topics': policy.topics.map(({
                                       topic,
                                       description,
                                       representativity,
                                       summary,
                                       count,
                                       subtopics
                                     }) =>
          ({topic, description, representativity, summary, count, subtopics}))
      }))
    };
    const reportData = await axiosEngineInstance.post(`${process.env.REACT_APP_ENGINE_URL}/generate-report`, payload, status200);
    setProgressMessage("The insights for the report have been compiled...");
    setProgress(70);
    await sleep(70);
    setProgressMessage("Generating the document for you...");
    setProgress(75)
    const aiData= reportData.data.report
    const pdfBlob = await exportPDF(schemeModified, schemeColor, aiData, description, organization, primary_color, secondary_color, palette.reverse(), convertSvgToPngBase64, image);
    setProgressMessage("Uploading the report to make it available for you...");
    setProgress(90);
    const fileName = normalizeString(`${organization.name}-${scheme.name}-${new Date().toISOString().slice(0, 16).replace('T', '-')}`);
    const pdfFile = new File([pdfBlob], `${fileName}.pdf`, {type: 'application/pdf'});
    const formData = new FormData();
    formData.append('image_file', pdfFile);
    const axiosResp = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/collection/upload`, formData).then((resp) => resp.data);
    const pdfUrl = axiosResp.file;
    updateScheme({
      ...scheme,
      reportUrl: pdfUrl,
      reportUpdateDate: new Date().toISOString().slice(0, 16).replace('T', ' ')
    });
    await sleep(70);
    setProgressMessage("Done!");
    setProgress(100);
    await sleep(70);
    setIsLoading(false);
    setProgress(0);
    setProgressMessage('');
    setShowProgressBar(false);
    setTitle('');
    if (onClose) {
      onClose(true);
    }
  };

  const sleep = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const onSubmit = handleSubmit((data) => (newReport(data)));

  const steps = [
    {
      label: `${t('general_information')}`,
      render: (
        <GeneralInformation
          t={t}
          theme={theme}
          methods={methods}
          charCount={charCount}
          charTitleCount={charTitleCount}
          fileName={fileName}
          error={error}
          handleTextChange={handleTextChange}
          handleTitleLengthChange={handleTitleLengthChange}
          handleDrop={handleDrop}
          handleDragOver={handleDragOver}
          handleFileSelect={handleFileSelect}
        />
      ),
      dataCy: 'general-information',
    },
    {
      label: t('questions'),
      dataCy: 'questions',
      render: (
        <Questions
          t={t}
          theme={theme}
          selectedAll={selectedAll}
          handleSwitchQuestionsChange={handleSwitchQuestionsChange}
          questionsCheckboxes={questionsCheckboxes}
          handleCheckboxQuestionChange={handleCheckboxQuestionChange}
        />
      )
    },
    {
      label: t('segments_variables'),
      dataCy: 'segments-variables',
      render: (
        <SegmentsVariables
          t={t}
          theme={theme}
          selectedSegmentsAll={selectedSegmentsAll}
          handleSwitchSegmentsChange={handleSwitchSegmentsChange}
          segmentsCheckboxes={segmentsCheckboxes}
          handleCheckboxSegmentsChange={handleCheckboxSegmentsChange}
        />
      )
    },
  ];

  const reportForm = (
    <ThemeProvider theme={themeCrowdView}>
      <Toast
        message={error}
        vertical='top'
        horizontal='center'
        severity='error'
        open={openToast}
        handleClose={handleCloseToast}
      />
      <MuiBoxContainer id={'stepper-box'} sx={{marginTop: '0px'}}>
        <MuiGridContainerStepper>
          <StepperContainer>
            <Stepper activeStep={activeStep} sx={stepperStyle}
                     orientation="horizontal">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <MuiCustomStepIcon {...props} />
                    )}
                    data-cy={step.dataCy}
                    onClick={handleStep(index)}
                    style={{cursor: 'pointer'}}
                    sx={{
                      '& .MuiStepIcon-root.Mui-completed': {
                        color: theme.palette.primary.main,
                      },
                      '& .MuiStepIcon-root.Mui-active': {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                <span style={{
                  ...labelStepStyle,
                  color: index === activeStep ? '#212121' : '#9E9E9E'
                }}
                >
                  {step.label}
                </span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </StepperContainer>
          {steps?.length > 0 && (
            <Grid container spacing={1} id={'step-content-container'} item
                  xs={true} sx={{
              paddingRight: '8px',
              paddingTop: '8px',
            }}>
              <Grid item xs={12} id={'step-description-container'}>
                <Box sx={{marginLeft: '16px'}}>{steps[activeStep].render}</Box>
              </Grid>
            </Grid>
          )}
        </MuiGridContainerStepper>
      </MuiBoxContainer>
    </ThemeProvider>
  );

  const actions = (
    <>
      <MuiButton
        onClick={() => onClose(false)}
        sx={{color: '#6793A5', borderColor: '#6793A5'}}
        variant="text"
      >
        <Typography sx={{fontFamily: 'Montserrat', fontSize: '14px'}}>
          {t('cancel_btn')}
        </Typography>
      </MuiButton>

      {activeStep !== 0 && (
        <MuiBtnNext
          style={{color: '#6793A5', borderColor: '#6793A5'}}
          variant="outlined"
          onClick={handleBack}
        >
          <Typography sx={{fontFamily: 'Montserrat', fontSize: '14px'}}>
            {t('go_back')}
          </Typography>
        </MuiBtnNext>
      )}

      {activeStep < steps.length - 1 ? (
        <MuiBtnNext
          style={{color: '#6793A5', borderColor: '#6793A5'}}
          variant="outlined"
          onClick={handleNext}
        >
          <Typography sx={{fontFamily: 'Montserrat', fontSize: '14px'}}>
            {t('continue')}
          </Typography>
        </MuiBtnNext>
      ) : (
        <MuiBtnNext
          onClick={onSubmit}
          variant="contained"
          type="submit"
          disabled={false}
          data-cy="btn_update_org"
        >
          <Typography sx={{fontFamily: 'Montserrat', fontSize: '14px'}}>
            {t(action)}
          </Typography>
        </MuiBtnNext>
      )}
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          onClose={() => onClose(false)}
          title={t('create_report')}
          actions={actions}
          widthInPixels={887}
        >
          {reportForm}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default GenerateReportModal;
