import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import * as _ from "lodash";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";
import {
  MuiCategoriesContainer,
  MuiCategoryTooltipWrapper,
  MuiChip,
  MuiOutlinedButton,
  MuiSecondaryButton,
  MuiSegmentContainer,
  MuiTextField
} from "./styles/customAiSegment";
import {generateID} from "../../../../../../../../utils/utils";
import axios from "../../../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../../../api/apiPaths";
import {status200} from "../../../../../../../../api/status.utils";
import {useSnackbar} from "notistack";
import {useLoading} from "../../../../../../../providers/LoadingProvider";
import {SegmentsModal} from "./SegmentModal";
import {ClassificationModal} from "./ClassificationModal";

const CategoryTooltip = ({category, index}) => {
  return (
    <Typography variant="inherit" key={`category-${category.topic}-${index}`}
                style={{color: 'inherit'}}>
      {category.topic}
    </Typography>
  )
}

const CustomCategories = ({categories}) => {
  if (!categories || categories.length === 0) {
    return <Typography variant="body2" style={{color: 'black'}}>No
      categories</Typography>;
  }

  return (
    <MuiCategoriesContainer>
      {categories.map((category, index) => (
        <MuiCategoryTooltipWrapper key={`category-${index}`}>
          <Tooltip
            title={<CategoryTooltip category={category} index={index}/>}>
            <div key={`tt-wrapper-${index}`}>
              <MuiChip
                label={
                  `${category.topic} ${category.representativity ? '(' + category.representativity.toFixed(0) + '%)' : ''}`
                }
                variant="outlined"
              />
            </div>
          </Tooltip>
        </MuiCategoryTooltipWrapper>
      ))}
    </MuiCategoriesContainer>
  );

}

const CustomAISegment = ({
                           segment,
                           segmentIndex,
                           collection,
                           answersVariable,
                           dataRegion,
                           handleDeleteSegment,
                           handleUpdateSegment,
                         }) => {
  const [open, setOpen] = React.useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const [openClassificationModal, setOpenClassificationModal] = React.useState(false);
  const {
    setIsClassificationLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();

  const {t} = useTranslation();


  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const successToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'success'})
  }

  const updateK = (k) => {
    const localSegment = _.cloneDeep(segment);
    localSegment.k = k;
    handleUpdateSegment(localSegment, segmentIndex);
  }

  const handleClassification = async () => {
    setShowProgressBar(true);
    setOpenClassificationModal(false);
    setProgress(0);
    setIsClassificationLoading(true);
    setTitle("Segmenting your data with custom categories...");
    let localSegment = _.cloneDeep(segment);
    const categories = localSegment?.categories
    let requestID = await submitCustomCategoryClassification(categories, localSegment.classificationVariable, localSegment.k || 1);
    if (requestID) {
      const representativityData = await pollTaskProgress(requestID)
      const updatedCategories = representativityData?.updatedTopics
      if (updatedCategories) {
        localSegment.categories = updatedCategories
        localSegment.classificationPerformed = true;
        handleUpdateSegment(localSegment, segmentIndex);
        successToast("Custom category successfully classified");
      } else {
        errorToast(`Failed to classify custom category'`)
      }

    }
    setShowProgressBar(false);
    setProgress(0);
    setProgressMessage('');
    setIsClassificationLoading(false);
  }


  const submitCustomCategoryClassification = async (categories, classification_variable, k) => {
    const requestId = generateID();
    const payload = {
      collection_name: collection,
      answers_column: answersVariable,
      topics: categories,
      model: "llm",
      requestId: requestId,
      saveClassification: true,
      classification_type: "generic",
      classificationVariable: classification_variable,
      max_topics: k,
      region: dataRegion
    };

    try {
      await axios.post(apiPaths.compute_async_classification, payload, status200);
    } catch (error) {
      console.error(error);
      errorToast(`Error computing custom segment classification`);
    }
    return requestId
  }


  const pollTaskProgress = async (requestId) => {
    let countFails = 0
    while (countFails < 10) {
      try {
        const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
        const response_body = progress_response.data;

        if (!response_body || !response_body.data) {
          await new Promise((resolve) => setTimeout(resolve, 1200));
          continue;
        }

        const {message, status, progress, additionalData} = response_body.data;

        if (status === 'failed' || status === 'error') {
          return null;

        } else if (status === 'success' && additionalData) {
          return additionalData;
        }

        setProgressMessage(`${message}`);
        let norm_progress = Math.min(progress, 100)
        setProgress(norm_progress);

      } catch (e) {
        console.error(e);
        countFails++;
      }
      await new Promise((resolve) => setTimeout(resolve, 1200));
    }
    return null;
  };

  return (
    <MuiSegmentContainer elevation={0}
                         key={`custom-segmentation-${segmentIndex}`}>
      <Grid container direction={'row'}>
        <Grid item xs={6}>
          <Typography sx={{
            height: '16px',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Raleway',
            letterSpacing: '0.001em',
            lineHeight: '16px',
            color: '#616161'
          }}>
            Category group {segmentIndex + 1}
          </Typography>
        </Grid>
        <Grid item xs={6} alignItems={'flex-end'}>
          <IconButton
            color="error"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              '&:hover': {backgroundColor: "#FFCFD2"},
              width: '26px',
              height: '26px',
              border: '1px solid #FFCFD2',
              borderRadius: '18px'
            }}
            onClick={() => handleDeleteSegment(segmentIndex)}
          >
            <DeleteIcon sx={{color: '#F63B2B', width: '16px', height: '16px'}}/>
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={2}
        direction={'column'}
        sx={{mt: 2}}
      >
        <Grid item xs={12}>
          <MuiTextField
            fullWidth
            label="Custom category label"
            value={segment.label || ""}
            onChange={(e) => {
              const newLabel = e.target.value;
              if (newLabel) {
                let localSegment = {...segment};
                localSegment.label = newLabel;
                handleUpdateSegment(localSegment, segmentIndex);
              }
            }}
          />
        </Grid>
        <Grid container direction='row' item xs={12}
              id="container-topic-category-section"
              style={{width: "100%", flex: 1}}>
          <Grid item xs={true}>
            <Box display="flex" flexWrap="wrap" gap={1}
                 flexDirection="column"
                 alignItems="flex-start">
              <CustomCategories categories={segment.categories}/>
            </Box>
          </Grid>
          <Grid container item xs={3} justifyContent='flex-end'>
            <FormControl variant="outlined">
              <MuiOutlinedButton
                variant="outlined"
                id="infer-category-btn"
                onClick={() => setOpen(true)}
              >
                Manage Categories
              </MuiOutlinedButton>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item sx={{paddingLeft: '16px', paddingTop: 0}}>
          <Divider sx={{width: '100%'}}/>
        </Grid>
        <Grid container item xs={12} spacing={'16px'}>
          <Grid item>
            <div>
              <MuiSecondaryButton
                onClick={() => setOpenClassificationModal(true)}
                variant="contained">
                {t('segment_answers')}
              </MuiSecondaryButton>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <SegmentsModal
        open={open}
        handleUpdateSegment={(segment) => {
          handleUpdateSegment(segment, segmentIndex)
          setOpen(false);

        }}
        segment={segment}
        handleClose={() => setOpen(false)}
      />
      <ClassificationModal
        open={openClassificationModal}
        onClose={() => setOpenClassificationModal(false)}
        submitClassification={handleClassification}
        k={segment.k || 1}
        updateK={updateK}
        amountCategories={segment?.categories?.length||1}
      />
    </MuiSegmentContainer>
  )
}

export default CustomAISegment;
