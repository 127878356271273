import {Button, styled} from "@mui/material";
import {darken,lighten} from "@mui/material/styles";

export const MuiKButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'selected' })(({ theme,selected }) => ({
  textTransform: 'none',
  width:'64px',
  height:'64px',
  borderRadius:'4px',
  backgroundColor:selected?lighten(theme.palette.primary.main, 0.8):'#E0E0E0',
  color:selected?darken(theme.palette.primary.main, 0.3):'#424242',
  border:`1px solid ${selected?darken(theme.palette.primary.main, 0.3):'#E0E0E0'}`,
  fontFamily:'Raleway',
  fontSize:'15px',
}));
