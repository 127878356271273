import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Grid, IconButton,
  LinearProgress, Popover,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';

import {useTheme} from "../../../providers/CustomThemeProvider";
import {
  MuiDivRoot,
  MuiGridCategoryWrapper,
  MuiGridElement,
  MuiGridSelectBar,
  MuiLink,
  MuiSkeleton,
  MuiTextField,
  MuiTypographyLabel,
  selectedCategoriesTitleStyles
} from '../../styles/categoryWidgetUI';
import {useTranslation} from '../../../providers/TranslationProvider';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const REST_CATEGORY = '__rest__';

function CategoryWidgetUI({
                            data,
                            maxItems,
                            order,
                            selectedCategories,
                            filterable,
                            onSelectedCategoriesChange,
                            isLoading
                          }) {


  const [sortedData, setSortedData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [showAll] = useState(true);
  const [blockedCategories, setBlockedCategories] = useState([]);
  const [animValues, setAnimValues] = useState([]);
  const prevAnimValues = usePrevious(animValues);
  const referencedPrevAnimValues = useRef();
  const {theme} = useTheme();
  CategoryWidgetUI.ORDER_TYPES = {
    RANKING: 'ranking',
    FIXED: 'fixed'
  };
  const {t} = useTranslation();

  const handleSearchChange = (event) => {
    setSearchValue(event.currentTarget.value);
  };

  const handleCategorySelected = (name) => {
    if (name !== REST_CATEGORY) {
      let categories;

      if (selectedCategories.indexOf(name) < 0) {
        categories = [...selectedCategories, name];
      } else {
        categories = selectedCategories.filter((c) => c !== name);
      }
      if (onSelectedCategoriesChange) {
        onSelectedCategoriesChange(categories);
      }
    }
  };

  const handleClearClicked = () => {
    onSelectedCategoriesChange([]);
  };

  const compressList = useCallback(
    (list) => {
      if (!showAll) {
        // Showing top or selected categories
        if (!blockedCategories.length) {
          const main = list.slice(0, maxItems);
          if (main.length < list.length) {
            const rest = list.slice(maxItems).reduce(
              (acum, elem) => {
                acum.value += elem.value;
                return acum;
              },
              {name: REST_CATEGORY, value: 0}
            );
            return [...main, rest];
          } else {
            return main;
          }

          // Showing only blocked categories
        } else {
          const main = blockedCategories.reduce((acum, name) => {
            const categoryElem = list.find((elem) => elem.name === name);
            acum.push({
              name,
              value: categoryElem ? categoryElem.value : null
            });
            return acum;
          }, []);
          return main;
        }

        // Showing all categories to block
      } else {
        return searchValue
          ? list.filter((elem) => {
            return (
              elem.name !== null &&
              elem.name !== undefined &&
              (elem.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
            );
          })
          : list;
      }
    },
    [blockedCategories, maxItems, searchValue, showAll]
  );

  const getCategoriesCount = useCallback(() => {
    const blocked = blockedCategories.length;
    return blocked ? (data.length - blocked).toLocaleString('en-US') : (data.length - maxItems).toLocaleString('en-US');
  }, [data, maxItems, blockedCategories]);

  const getCategoryLabel = useCallback(
    (name) => {
      if (name === REST_CATEGORY) {
        return `Others (${getCategoriesCount()})`;
      } else {
        return `${name}`;
      }
    },
    [getCategoriesCount]
  );

  const getProgress = (value) => {
    if (sortedData?.length > 0) {
      let total = sortedData.reduce((a, b) => a + b.value, 0)
      return ((value || 0) * 100) / (sortedData[0].total || total);
    }
  }

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setBlockedCategories([]);
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (data) {
      // Ranking
      if (order === CategoryWidgetUI.ORDER_TYPES.RANKING) {
        const sorted = [...data].sort((a, b) => b.value - a.value);
        const compressed = compressList(sorted);
        setSortedData(compressed);

        // Fixed order
      } else {
        const sorted = [...data].sort((a, b) => a.name.localeCompare(b.name));
        const compressed = compressList(sorted);
        setSortedData(compressed);
      }
    }
  }, [
    blockedCategories,
    compressList,
    data,
    maxItems,
    order,
    searchValue,
    showAll
  ]);

  useEffect(() => {
    referencedPrevAnimValues.current = prevAnimValues;
  }, [prevAnimValues]);

  useEffect(() => {
    setAnimValues(sortedData);
  }, [sortedData]);


  // Separated to simplify the widget layout but inside the main component to avoid passing all dependencies
  const CategoryItem = (props) => {
    const {data, onCategoryClick, filterable} = props;
    const value = data.value;
    const total = data.total;
    const [isOverflowed, setIsOverflowed] = useState(false);
    const textElementRef = useRef();
    const itemContainerRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const popoverId = open ? `popover-${data.name}-${data.value}` : undefined;
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const hasSummary = !!data?.summary;
    // console.log("Cat item data",data);
    const compareSize = () => {
      const compare =
        textElementRef?.current?.scrollWidth > textElementRef?.current?.clientWidth;
      setIsOverflowed(compare);
    };

    useEffect(() => {
      compareSize();
      window.addEventListener('resize', compareSize);
      return () => {
        window.removeEventListener('resize', compareSize);
      };
    }, []);

    return (
      <Grid
        container
        id={"item-container"}
        ref={itemContainerRef}
        wrap='nowrap'
        style={{marginBottom: '5px'}}
      >
        <Grid item xs={1} onClick={filterable ? onCategoryClick : () => {
        }}>
          <Checkbox disabled={!filterable} style={{
            color: selectedCategories.indexOf(data.name) !== -1 ? theme.palette.primary.main : '#919499'
          }}
                    checked={selectedCategories.indexOf(data.name) !== -1}
                    size="small"/>
        </Grid>
        <Grid
          container
          item
          onClick={filterable ? onCategoryClick : () => {
          }}
          direction='column'
          xs={hasSummary ? 10 : 11}
          wrap='nowrap'
          aria-disabled={selectedCategories.indexOf(data.name) === -1 && selectedCategories.length > 0}
          style={{
            paddingLeft: "8px",
            cursor: filterable ? "pointer" : "default"
          }}
        >
          <Grid container item direction='row' alignItems='center'
                style={{width: '100%'}}>
            <Grid item xs style={{display: 'flex', minWidth: 0}}>
              <Tooltip
                title={getCategoryLabel(data.name)}
                disableHoverListener={!isOverflowed}
              >
                <MuiTypographyLabel
                  variant='body2'
                  ref={textElementRef}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%', // Ensure this is set so that the text knows when to overflow
                  }}
                >
                  {getCategoryLabel(data.name)}
                </MuiTypographyLabel>
              </Tooltip>
            </Grid>
            <Grid item>
              {typeof value === 'object' && value !== null ? (
                <MuiTypographyLabel>
                  {value.prefix}
                  {value.value?.toLocaleString('en-US')}
                  {value.suffix}
                </MuiTypographyLabel>
              ) : (
                <MuiTypographyLabel
                  variant='body2'>{value?.toLocaleString('en-US')} {total ? `/ ${total.toLocaleString("en-US")}` : ''}</MuiTypographyLabel>
              )}
            </Grid>
          </Grid>
          <Grid item style={{
            height: '5px',
            width: '100%',
          }}>
            <LinearProgress
              color='primary'
              variant='determinate'
              value={getProgress(data.value)}
              sx={{
                height: '100%',
                width: '100%',
                maxWidth: '100%',
                borderRadius: '5px',
                backgroundColor: selectedCategories.indexOf(data.name) === -1 ? (selectedCategories.length > 0 ? '#d9d7d7' : '') : '', // Setting the background color for the whole bar
                '& .MuiLinearProgress-barColorPrimary': {
                  backgroundColor: selectedCategories.indexOf(data.name) === -1 ? (selectedCategories.length > 0 ? '#919499' : '') : ''
                },
              }}
            />
          </Grid>
        </Grid>
        {hasSummary && <Grid item xs={1}>
          <IconButton onClick={handleClick}>
            <InfoOutlinedIcon fontSize={'small'}/>
          </IconButton>
        </Grid>}
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            maxWidth: 480,
            maxHeight: 250,
            ml: '-20px'
          }}
        >
          <Typography sx={{
            p: 1,
            width: itemContainerRef?.current?.clientWidth
          }}>{data.summary}</Typography>
        </Popover>
      </Grid>
    );
  };

  const CategoryItemSkeleton = () => (
    <>
      <MuiGridSelectBar
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography variant='caption'>
          <Skeleton variant='text' width={100}/>
        </Typography>
      </MuiGridSelectBar>
      <MuiGridCategoryWrapper container item spacing={3}>
        {[...Array(4)].map((_, i) => (
          <MuiGridElement key={i} container direction='row' spacing={3}>
            <Grid container item xs zeroMinWidth>
              <Grid container item direction='row'
                    justifyContent='space-between'>
                <Typography variant='body2' noWrap>
                  <Skeleton variant='text' width={100}/>
                </Typography>
                <Typography variant='body2'>
                  <Skeleton variant='text' width={70}/>
                </Typography>
              </Grid>
              <MuiSkeleton variant='text'/>
            </Grid>
          </MuiGridElement>
        ))}
      </MuiGridCategoryWrapper>
    </>
  );

  return (
    <MuiDivRoot style={{maxWidth: '100%', paddingTop: '5px'}}>
      {data?.length > 0 ? (
        <>
          {filterable && sortedData.length > 0 && (
            <MuiGridSelectBar
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='caption'
                          style={selectedCategoriesTitleStyles}>
                {selectedCategories.length && selectedCategories.length < sortedData.length ? selectedCategories.length : `${t('all')}`} {t('selected')}
              </Typography>
              {
                (
                  selectedCategories.length > 0 && (
                    <Grid container direction='row' justifyContent='flex-end'
                          item xs>
                      <MuiLink onClick={handleClearClicked}>
                        Clear
                      </MuiLink>
                    </Grid>
                  )
                )}
            </MuiGridSelectBar>
          )}
          {data.length > maxItems && showAll && (
            <MuiGridSelectBar
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <MuiTextField
                size='small'
                placeholder={t('search_field')}
                variant={'standard'}
                onChange={handleSearchChange}
                InputProps={{
                  style: {alignItems: 'center', minHeight: '30px'},
                  disableUnderline: true
                }}
                style={{flexGrow: 1, width: '100%'}}
              />
            </MuiGridSelectBar>
          )}
          <MuiGridCategoryWrapper container item>
            {animValues.length ? (
              animValues.map((d, i) => (
                <CategoryItem
                  key={i}
                  data={d}
                  filterable={filterable}
                  onCategoryClick={() => {
                    if (filterable) {
                      handleCategorySelected(d.name)
                    }
                  }
                  }
                />
              ))
            ) : (
              <>
                <Typography
                  variant='body2'>{isLoading ? 'Loading...' : 'No data available'}</Typography>
              </>
            )}
          </MuiGridCategoryWrapper>
        </>
      ) : (
        <CategoryItemSkeleton/>
      )}
    </MuiDivRoot>
  );
}

CategoryWidgetUI.ORDER_TYPES = {
  RANKING: 'ranking',
  FIXED: 'fixed'
};

CategoryWidgetUI.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
        .isRequired,
      value: PropTypes.number
    })
  ),
  formatter: PropTypes.func,
  maxItems: PropTypes.number,
  selectedCategories: PropTypes.array,
  onSelectedCategoriesChange: PropTypes.func,
  order: PropTypes.oneOf(Object.values(CategoryWidgetUI.ORDER_TYPES)),
  animation: PropTypes.bool,
  filterable: PropTypes.bool,
  searchable: PropTypes.bool
};

export default CategoryWidgetUI;
