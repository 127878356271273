import {Grid, IconButton, styled, Tab, Tabs, TextField} from '@mui/material';

export const MuiTabsInsights = styled(Tabs)(({theme}) => ({
  padding: theme.spacing(0),
  marginTop: theme.spacing(0),
  marginLeft: theme.spacing(1),
  borderBottom: '1px solid',
  scrollableX: true,
  borderColor: theme.palette.secondary.main,
  '& .Mui-selected': {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    color: theme.palette.primary.main,
    transition: 'font-size 10ms, font-weight 10ms',
  },
  '& .MuiTabs-indicator': {
    height: 3,
    width: '0',
    borderRadius: '10px 10px 0 0',
    transition: 'width 0.3s',
    marginBottom: theme.spacing(0)
  },
  '& .MuiTabs-indicator[data-indicator-color="secondary"]': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const MuiSearchIconButton = styled(IconButton, {shouldForwardProp: (prop) => prop !== 'clear'})(({theme,clear}) => ({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  backgroundColor: (clear?'#EEEEEE':theme.palette.primary.main),
  '&:hover': {
    backgroundColor: (clear?'#EEEEEE':theme.palette.primary.main),
  }
}))
export const MuiTab = styled(Tab)(({theme}) => ({
  minWidth: 'auto',
  padding: theme.spacing(0),
  marginLeft: theme.spacing(3),
}));

export const MuiTextField = styled((props) => (<TextField
  {...props}
  variant="standard"
  fullWidth
  multiline
  maxRows={2}
/>))(({theme}) => ({
  fontSize: '14px',
  fontFamily: 'Montserrat',
  marginLeft: '1px',
  lineHeight:'17px',
  width: '100%',
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '22px',
    minHeight: '42px',
    padding: '4px 5px 4px 24px', // Adjust padding to center content
    boxSizing: 'border-box', // Ensures border and padding are included in the element's total height and width
    display: 'flex', // Flexbox for alignment
    alignItems: 'center', // Align text to the top for multiline
  },
  '& .MuiInputBase-inputMultiline': {
    padding: '0px', // Ensure no padding conflicts with the content alignment
    fontSize: '14px',
    fontFamily: 'Montserrat',
    letterSpacing: '0.005em',
    boxSizing: 'border-box',
    color: '#143440',
    lineHeight: '17px', // Ensure proper line height for readability
    minHeight: 'auto', // Allow multiline to grow with content
    height: '42px',
    display: 'flex',
    alignItems: 'center', // Centers the text inside each row
    '::placeholder': {
      color: '#143440',
      opacity: 1,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.005em',
      fontFamily: 'Montserrat',
    },
  },
  '& input': {
    fontSize: '14px',
    fontFamily: 'Montserrat',
    padding: '0px', // Ensure no vertical padding
    height: '100%', // Take full height of the container
    display: 'flex', // Flexbox for alignment
    alignItems: 'center', // Centers text vertically
    letterSpacing: '0.005em',
    boxSizing: 'border-box', // Ensures border and padding are included in the element's total height and width
    lineHeight: '17px', // Adjust line height for proper centering
    color: '#143440',
    '::placeholder': {
      color: '#143440',
      opacity: 1,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.005em',
      fontFamily: 'Montserrat',
    }
  },
}));


export const MuiQAGrid = styled(Grid)(({theme, historyMaxHeight}) => ({
  width: '100%',
  minHeight: "200px",
  overflowY: 'auto'
}));

export const divTabStyle = {
  display: 'flex',
  alignItems: 'center'
}
export const titleTabStyle = {
  fontSize: '14px',
  fontFamily: 'Montserrat',
  fontWeight: 600
}

export const selectedHistogramTitleStyles = {
  color: '#424242',
  fontFamily: 'Montserrat',
  fontSize: '12px',
};

export const containerSimilarityStyles = {
  width: '100%',
  minHeight: '0px',
  height: 'fit-content',
};

export const dividerStyles = {
  width: '100%',
  marginTop: '16px',
  marginBottom: "16px"
};
