import React, {useEffect, useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import axiosEngineInstance
  from "../../../../../../../../api/axios/axiosEngineInstance";
import {generateID} from "../../../../../../../../utils/utils";
import axios from "../../../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../../../api/apiPaths";
import {status200} from "../../../../../../../../api/status.utils";
import {useLoading} from "../../../../../../../providers/LoadingProvider";
import {enqueueSnackbar} from "notistack";
import {
  BadgeTopics,
  cardMedia,
  labelStyle,
  MuiBoxButtonsAction,
  MuiTypography,
  selectStyle,
  styleBoxCard
} from "../styles/imageGeneration";
import {MuiButtonAction} from "../../../styles/configurationSteps";
import SplitIcon from "@mui/icons-material/CallSplit";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
  ReactComponent as GenerateImagesSvg
} from '../../../../../../../../assets/img/generate_images.svg';
import {
  MuiEmptyTopicsHeadingContainer,
  MuiHighlightedText
} from "./styles/imageGeneration";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";

const styles = ["Photorealistic", "Realistic", "Surreal", "Minimalist", "Abstract", "Cartoon", "Vintage", "Futuristic", "Impressionistic", "Expressionistic", "Black and White"];
const dramaticLevels = ["Tranquil", "Neutral", "Engaging", "Moderate", "Dramatic"];
const complexities = ["Simple", "Moderate", "Complex"];
const valences = ["Neutral", "Positive", "Very Positive", "Negative", "Very Negative"];

const TopicImageManager = ({
                             policy,
                             dataset,
                             updatePolicy,
                             analysisObjective,
                             contextDescription,
                             audienceDescription
                           }) => {
  const [topics, setTopics] = useState([...policy.topics]);
  const [generalParams, setGeneralParams] = useState({
    style: policy.imageSettings?.style || "Photorealistic",
    dramaticLevel: policy.imageSettings?.dramaticLevel || "Neutral",
    imageComplexity: policy.imageSettings?.imageComplexity || "Complex",
    emotionalValence: policy.imageSettings?.emotionalValence || "Neutral",
    region: policy.imageSettings?.region || "Not specified",
    specialInstructions: policy.imageSettings?.specialInstructions || "",
    model: policy.imageSettings?.model || "stable-diffusion",
  });
  const [overrideTopicImages, setOverrideTopicImages] = useState(false);

  const [anyImagePresent, setAnyImagePresent] = useState(false);

  const [selectedTopicIndex, setSelectedTopicIndex] = useState(null);

  const {t, lng} = useTranslation();

  const handleViewSubtopics = (index) => {
    setSelectedTopicIndex(index);
  };
  const handleBackToTopics = () => {
    setSelectedTopicIndex(null);
  };

  const {
    setIsLoading,
    setIsTopicInferenceLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();

  useEffect(() => {
    setTopics(policy.topics);
    if (policy.imageSettings) {
      let localGeneralParams = {
        style: policy.imageSettings?.style || "Photorealistic",
        dramaticLevel: policy.imageSettings?.dramaticLevel || "Neutral",
        imageComplexity: policy.imageSettings?.imageComplexity || "Complex",
        emotionalValence: policy.imageSettings?.emotionalValence || "Neutral",
        region: policy.imageSettings?.region || "Not specified",
        specialInstructions: policy.imageSettings?.specialInstructions || "",
        model: policy.imageSettings?.model || "stable-diffusion",
      };
      for (const [key, value] of Object.entries(policy.imageSettings)) {
        if (value) {
          localGeneralParams[key] = value;
        }
      }
      setGeneralParams(localGeneralParams);
    }
    setAnyImagePresent(policy.topics.reduce((acc, topic) => acc || topic.image_url, false));
  }, [policy]);

  const handleParamChange = (e) => {
    const updatedSettings = {...generalParams, [e.target.name]: e.target.value};
    setGeneralParams(updatedSettings);
  };

  const handleGenerateTopicImage = async (index) => {
    setTitle("Turning your insights into art...");
    setProgress(0)
    setProgressMessage("This will only take a few minutes...")
    setShowProgressBar(false);
    setIsTopicInferenceLoading(true);
    setIsLoading(true);
    const topic = topics[index];
    topic.image_url = null
    const url = `${process.env.REACT_APP_ENGINE_URL}/generate-topic-images`;
    const requestId = generateID();
    await axiosEngineInstance.post(url, {
      surveyId: dataset,
      questionId: policy.answerVar,
      topics: [topic],
      ...generalParams,
      requestId: requestId,
      analysisObjective: analysisObjective || '',
      contextDescription: contextDescription || '',
      audienceDescription: audienceDescription || '',
      originalQuestionText: policy.longName || ''
    }).then((res) => {
      const updatedTopics = res.data?.updatedTopics.updatedTopics;
      if (updatedTopics) {
        let localPolicy = {...policy};
        const imageUrl = updatedTopics[0].image_url;
        if (imageUrl) {
          const newTopics = [...topics];
          newTopics[index] = updatedTopics[0];
          localPolicy.topics = newTopics;
          updatePolicy(localPolicy);
          setTopics(newTopics);
        }
      }
      return updatedTopics;
    }).catch((err) => {
      console.log("Error generating images", err);
      errorToast("Error generating images");
      return null;
    });
    setIsLoading(false);
    setIsTopicInferenceLoading(false);
    setShowProgressBar(false);
    setProgress(0);
    setTitle("");
  };

  const generateSubtopicImages = async (index) => {
    const topic = topics[index];
    setTitle(`Turning your insights about "${topic.topic}" into art...`);
    setProgress(0)
    setProgressMessage("This will only take a few minutes...")
    setShowProgressBar(true);
    setIsTopicInferenceLoading(true);
    setIsLoading(true);
    const subtopics = topic.subtopics.map(st => ({...st, image_url: null}));
    const requestId = submit_images_generation(subtopics);
    const jobData = await pollTaskProgress(requestId);
    if (jobData?.updatedTopics?.length > 0) {
      let localPolicy = {...policy};
      const newTopics = [...topics];
      const updatedSubtopics = jobData.updatedTopics;
      const currentSubtopics = newTopics[index].subtopics;
      updatedSubtopics.forEach((subtopic, index) => {
        if (!subtopic.image_url) {
          if (currentSubtopics[index].topic === subtopic.topic) {
            subtopic.image_url = currentSubtopics[index].image_url
          } else {
            const current = currentSubtopics.find(t => t.topic === subtopic.topic);
            if (current) {
              subtopic.image_url = current.image_url
            }
          }
        }
      })
      newTopics[index].subtopics = updatedSubtopics;
      localPolicy.topics = newTopics;
      localPolicy.imageSettings = generalParams;
      updatePolicy(localPolicy);
      setTopics(newTopics);
    } else {
      errorToast("Error generating images");
    }
    setIsLoading(false);
    setIsTopicInferenceLoading(false);
    setShowProgressBar(false);
    setProgressMessage('');
    setProgress(0);
    setTitle("");
  }

  const handleDescriptionChange = (index, description) => {
    const newTopics = [...topics];
    if (selectedTopicIndex !== null) {
      newTopics[selectedTopicIndex].subtopics[index].image_description = description;
      newTopics[selectedTopicIndex].subtopics[index].image_url = null;
      setTopics(newTopics);
    } else {
      newTopics[index].image_description = description;
      newTopics[index].image_url = null;
      setTopics(newTopics);
    }
  };

  function submit_images_generation(topics_submit) {
    const url = `${process.env.REACT_APP_ENGINE_URL}/generate-topic-images`;
    const requestId = generateID();
    axiosEngineInstance.post(url, {
      surveyId: dataset,
      questionId: policy.answerVar,
      topics: topics_submit,
      ...generalParams,
      requestId: requestId,
      analysisObjective: analysisObjective || '',
      contextDescription: contextDescription || '',
      audienceDescription: audienceDescription || '',
      originalQuestionText: policy.longName || ''
    }).then((res) => {
      return res.data;
    }).catch((err) => {
      console.log("Error generating images", err);
      return null;
    })
    return requestId;
  }

  const pollTaskProgress = async (requestId) => {
    let countFails = 0
    while (countFails < 10) {
      try {
        const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
        const response_body = progress_response.data;

        if (!response_body || !response_body.data) {
          await new Promise((resolve) => setTimeout(resolve, 1200));
          continue;
        }

        const {message, status, progress, additionalData} = response_body.data;

        if (status === 'failed' || status === 'error') {
          return null;

        } else if (status === 'success' && additionalData) {
          return additionalData;
        }

        setProgressMessage(`${message}`);
        let norm_progress = Math.min(progress, 100)
        setProgress(norm_progress);

      } catch (e) {
        console.error(e);
        countFails++;
      }
      await new Promise((resolve) => setTimeout(resolve, 1200));
    }
    return null;
  };

  const handleGenerateImages = async () => {
    setTitle("Turning your insights into art...");
    setProgress(0)
    setProgressMessage("This will only take a few minutes...")
    setShowProgressBar(true);
    setIsTopicInferenceLoading(true);
    setIsLoading(true);
    const preprocessed = topics.map((topic) => {
      return {
        ...topic,
        image_url: overrideTopicImages ? null : topic.image_url,
        subtopics: topic.subtopics?.map((subtopic) => {
          return {
            ...subtopic,
            image_url: overrideTopicImages ? null : subtopic.image_url
          }
        }) || []
      }
    })
    const requestId = submit_images_generation(preprocessed);
    const jobData = await pollTaskProgress(requestId);
    if (jobData?.updatedTopics?.length > 0) {
      const localPolicy = {
        ...policy,
        topics: jobData.updatedTopics,
        imageSettings: generalParams
      };

      updatePolicy(localPolicy);
    } else {
      errorToast("Failed to generate images");
    }
    setIsLoading(false);
    setIsTopicInferenceLoading(false);
    setShowProgressBar(false);
    setProgress(0);
    setTitle("");
    setProgressMessage('');
  };

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const highlightedText = {
    "spanish": "GENERAR IMÁGENES",
    "english": "GENERATE IMAGES",
    "portuguese": "GERAR IMAGENS",
    "french": "GÉNÉRER DES IMAGES",
    "arabic": "GENERATE IMAGES"
  }

  const getEmptyTopicsText = () => {
    const text = t('empty_images_heading')
    const [before, after] = text.split(highlightedText[lng])
    return (
      <MuiEmptyTopicsHeadingContainer>
        {before}
        <MuiHighlightedText>&nbsp;"{highlightedText[lng]}"&nbsp;</MuiHighlightedText>{after}
      </MuiEmptyTopicsHeadingContainer>
    )
  }
  return (
    <Grid container direction={'row'} id={'images-container'}
          sx={{width: '100%', maxWidth: '100%'}} wrap={'nowrap'}>
      <Grid container item direction={'row'} wrap={'nowrap'} sx={{
        paddingLeft: '16px',
        maxWidth: '261px',
        paddingRight: '16px',
        borderRight: '2px solid #E0E0E0'
      }}>
        <Grid container direction={'column'} item sx={{width: '229px'}}>
          <Typography sx={{
            fontFamily: 'Raleway',
            fontWeight: 400,
            fontSize: 16,
            mb: '16px',
          }}> {t('image_settings')}</Typography>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel sx={labelStyle}>{t('image_style')}</InputLabel>
            <Select name="style" value={generalParams.style}
                    label={t('image_style')}
                    onChange={handleParamChange} sx={selectStyle}>
              {styles.map((style) => (
                <MenuItem key={style} value={style}><MuiTypography
                  variant="body1">{style}</MuiTypography></MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel sx={labelStyle}>{t('dramatic_level')}</InputLabel>
            <Select name="dramaticLevel" value={generalParams.dramaticLevel}
                    label={t('dramatic_level')}
                    onChange={handleParamChange} sx={selectStyle}>
              {dramaticLevels.map((level) => (
                <MenuItem key={level} value={level}><MuiTypography
                  variant="body1">{level}</MuiTypography></MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel sx={labelStyle}>{t('image_complexity')}</InputLabel>
            <Select name="imageComplexity"
                    value={generalParams.imageComplexity}
                    onChange={handleParamChange} label={t('image_complexity')}
                    sx={selectStyle}>
              {complexities.map((complexity) => (
                <MenuItem key={complexity}
                          value={complexity}>
                  <MuiTypography
                    variant="body1">{complexity}</MuiTypography></MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel sx={labelStyle}>{t('emotional_valence')}</InputLabel>
            <Select name="emotionalValence"
                    value={generalParams.emotionalValence}
                    onChange={handleParamChange} label={t('emotional_valence')}
                    sx={selectStyle}>
              {valences.map((valence) => (
                <MenuItem key={valence} value={valence}><MuiTypography
                  variant="body1">{valence}</MuiTypography></MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              label={t('region')}
              name="region"
              value={generalParams.region}
              onChange={handleParamChange}
              margin="normal"
              variant="outlined"
              sx={selectStyle}
            />
            <TextField
              fullWidth
              label={t('special_instructions')}
              name="specialInstructions"
              value={generalParams.specialInstructions}
              onChange={handleParamChange}
              margin="normal"
              variant="outlined"
              sx={selectStyle}
            />
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel sx={labelStyle}>{t('model')}</InputLabel>
            <Select name="model"
                    value={generalParams.model}
                    onChange={handleParamChange} label={t('model')}
                    sx={selectStyle}>
              <MenuItem key={'stable-diffusion'} value={'stable-diffusion'}>Stable
                Diffusion</MenuItem>
              <MenuItem key={'dalle'} value={'dalle'}>DALL-E</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={overrideTopicImages}
                  onChange={(evt) => setOverrideTopicImages(evt.target.checked)}/>
              }
              label={t('override_existing_images')}
              sx={{
                '& .MuiTypography-root': {
                  fontFamily: 'Montserrat'
                }
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <MuiButtonAction onClick={handleGenerateImages} sx={{mt: '8px'}}>
              {t('generate_images')}
            </MuiButtonAction>
          </FormControl>
        </Grid>
      </Grid>{/*Finish settings*/}
      <Grid container item direction='column' xs={true} sx={{
        paddingLeft: '16px',
        paddingRight: '16px',
        overflow:'hidden'
      }} id={'images-section'}>
        <Grid item>
          <Typography sx={{
            fontFamily: 'Raleway',
            fontWeight: 400,
            fontSize: 16,
            mb: '16px'
          }}>Images </Typography>
        </Grid>
        {anyImagePresent ? (
          <Grid container item alignContent='center'
                id={'card-container-grid'}
                sx={{width: '100%', maxWidth: '100%'}}>
            <Box
              id={'carousel-box'}
              sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
                gap: 2,
                padding: '0 16px 16px 0',
                scrollBehavior: 'smooth',
                scrollbarWidth: 'thin',
                overflowX: 'auto',
                flexWrap:'nowrap',
                '&::-webkit-scrollbar': {
                  height: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#999',
                  borderRadius: '3px',
                }
              }}
            >
              {selectedTopicIndex === null ? (
                topics.map((topic, index) => (
                  <Card key={index} sx={styleBoxCard} id={`Card-${index}`}>
                    {topic.image_url && (
                      <CardMedia
                        component="img"
                        image={topic.image_url}
                        alt={topic.topic}
                        sx={cardMedia}
                      />
                    )}
                    <CardContent
                      sx={{
                        padding: 0,
                        '&:last-child': {
                          paddingBottom: 0
                        }
                      }}>
                      <div style={{
                        gap: 14,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 245
                      }}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            mt: '14px',
                            height: '40px'
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Montserrat',
                              fontSize: '16px',
                              fontWeight: 700,
                              fontSpacing: 0.5,
                            }}
                          >
                            {topic.topic}
                          </Typography>
                          {topic.subtopics?.length > 0 && (
                            <BadgeTopics
                              badgeContent={topic.subtopics.length}>
                              <SplitIcon style={{rotate: '180deg'}}/>
                            </BadgeTopics>
                          )}
                        </Box>
                        <TextField
                          label="Image Description"
                          multiline
                          value={topic.image_description}
                          onChange={(e) => handleDescriptionChange(index, e.target.value)}
                          rows={9}
                          InputLabelProps={{
                            shrink: true,
                            style: {
                              fontFamily: 'Montserrat',
                              fontSize: '12px',
                              fontWeight: 600,
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-root': {
                              height: '165px'
                            },
                            mt: '3px',
                            '& .MuiOutlinedInput-root': {
                              fontFamily: 'Montserrat',
                              fontSize: '12px',
                              fontWeight: 400,
                              letterSpacing: '0.4px',
                              padding: '12px 0 4px 16px'
                            },
                          }}
                        />
                      </div>
                      <MuiBoxButtonsAction sx={{gap: 1}}>
                        <MuiButtonAction
                          onClick={() => handleGenerateTopicImage(index)}>
                          {t('update_image')}
                        </MuiButtonAction>
                        {topic.subtopics?.length > 0 && (
                          <>
                            <MuiButtonAction
                              onClick={() => generateSubtopicImages(index)}>
                              {t('update_subtopic_images')}
                            </MuiButtonAction>
                            <MuiButtonAction
                              onClick={() => handleViewSubtopics(index)}>
                              {t('view_subtopics')}
                            </MuiButtonAction>
                          </>
                        )}
                      </MuiBoxButtonsAction>
                    </CardContent>
                  </Card>
                ))) : (
                <Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    alignItems: 'center'
                  }}>
                    <IconButton
                      onClick={handleBackToTopics}><ArrowBackOutlinedIcon/></IconButton>
                    <Typography variant="subtitle1" sx={{
                      fontFamily: 'Raleway',
                      fontWeight: 600
                    }}>{topics[selectedTopicIndex].topic}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      overflowX: 'auto',
                      gap: 2,
                      padding: '0 16px 16px 0',
                      scrollbarWidth: 'thin',
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }}
                  >
                    {topics[selectedTopicIndex].subtopics.map((subtopic, subIndex) => (
                      <Card key={subIndex}
                            sx={{...styleBoxCard, paddingBottom: '16px'}}>
                        {subtopic.image_url && (
                          <CardMedia
                            component="img"
                            image={subtopic.image_url}
                            alt={subtopic.topic}
                            sx={cardMedia}
                          />
                        )}
                        <CardContent
                          sx={{
                            padding: 0,
                            '&:last-child': {
                              paddingBottom: 0
                            }
                          }}>
                          <div style={{
                            gap: 14,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 245
                          }}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                mt: '14px',
                                height: '40px'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: 'Montserrat',
                                  fontSize: '16px',
                                  fontWeight: 700,
                                  fontSpacing: 0.5,
                                }}
                              >
                                {subtopic.topic}
                              </Typography>
                            </Box>
                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: 'Montserrat',
                                fontSize: '14px',
                                fontWeight: 400,
                                height: '60px',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 3,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {subtopic.description}
                            </Typography>
                            <TextField
                              label="Image Description"
                              multiline
                              value={subtopic.image_description}
                              onChange={(e) => handleDescriptionChange(subIndex, e.target.value)}
                              rows={4}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  fontFamily: 'Montserrat',
                                  fontSize: '12px',
                                  fontWeight: 600,
                                },
                              }}
                              sx={{
                                mt: '10px',
                                '& .MuiOutlinedInput-root': {
                                  fontFamily: 'Montserrat',
                                  fontSize: '12px',
                                  fontWeight: 400,
                                  letterSpacing: '0.4px',
                                  padding: '12px 0 4px 16px'
                                },
                              }}
                            />
                          </div>
                          <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            width: '100%'
                          }}>
                            <MuiButtonAction
                              onClick={() => handleGenerateTopicImage(subIndex)}>
                              {t('update_image')}
                            </MuiButtonAction>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        ) : (<Box sx={{
          width: '100%',
          height: '100%'
        }}>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item sx={{
              height: '100%',
              width: '100%',
              paddingTop: '100px',
              paddingLeft: '67px',
              paddingRight: '59px'
            }}>
              <GenerateImagesSvg width={'100%'} height={'auto'}/>
            </Grid>
            <Grid item sx={{paddingLeft: '67px', mt: '38px'}}>
              {getEmptyTopicsText()}
            </Grid>
          </Grid>
        </Box>)}
      </Grid>
    </Grid>
  );
};

export default TopicImageManager;
