import {LinearProgress, styled, Typography} from '@mui/material';


export const MuiDivRoot  = styled("div")(() => ({
  width: '100%',
  minWidth:'100px',
  paddingLeft:'25px',
  paddingRight: 1.5,
  paddingTop: '5px',
  overflowY: 'auto',
  '&, & *': {
    '&::-webkit-scrollbar': {
      backgroundColor: '#ebeef2',
      height: 5,
      borderRadius: 3,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#d3dbe5',
      borderRadius: 3,
    },
  },
}));

export const MuiTypography= styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight:'400',
  lineHeight:'15px',
  letterSpacing:"0.004em",
  fontFamily:'Montserrat',
  color: '#424242',
  textAlign: 'justify',
  fontStyle:'italic'
}));

export const MuiLinearProgress = styled(LinearProgress)(({theme}) => ({
  top: 0,
  left: 0,
  width: '100%',
  height: theme.spacing(0.25)
}));


export const gridContainerStyle = {
  width: '100%',
  maxHeight:"100%"
};

export const gridItemStyle = {
  width: '100%',
  height: '10px'
};

export const divAnswerStyle = {
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection:'column',
  gap:'10px',
  marginBottom:'0px',
  marginTop:'16px',
  background:'#FAFAFA'
};

export const tablePaginationStyle = {
  width: '380px',
  maxWidth: '380px',
  '.MuiToolbar-root': {
    minHeight:'50px'
  },
  '.MuiTablePagination-actions': {
    display: 'flex',
    marginRight:'10px',
    gap: '8px',
    '& button': {
      margin: '0',
      padding: '0',
    },
  },
  '.MuiTablePagination-displayedRows': {
    width: '200px',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    letterSpacing: '0.004em',
    color: '#475569',
    minWidth: '120px',
    textAlign: 'center',
  },
};

