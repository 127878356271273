import {Box, Button, Modal, Typography} from "@mui/material";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

export const UpdateNotificationModal = ({
                                          updateAvailable,
                                          setUpdateAvailable,
                                          updatedAppVersion
                                        }) => {

  const reloadPage = async () => {
    try {
      if ('caches' in window) {
        window.caches.keys().then((keys => {
          keys.forEach(key => {
            window.caches.delete(key);
          })
        }))
      } else {
        console.log("No caches entry in window");
      }
    } catch (e) {
      console.warn("Couldn't clear cache", e);
    }
    localStorage.setItem("appVersion", updatedAppVersion);
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      })
    })
    window.location.reload();
  }

  return (
    <Modal open={updateAvailable} onClose={() => setUpdateAvailable(false)}
           sx={{backgroundColor: 'transparent !important'}}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '458px',
        border: '0px solid #FFF',
        borderRadius: '12px 0px 12px 12px',
        backgroundColor: '#fff',
        position: 'absolute',
        top: '68px', // Adjust the distance from the top
        right: '5px', // Adjust the distance from the right
        boxShadow: 'none', // Optional: Add a shadow for better visibility
        zIndex: 1300, // Ensure it stays above other elements
        padding: '12px 16px 12px 16px',
        gap: '8px',
        alignItems: 'center',
        opacity: 1,
        animation: `slideInFromRight 0.5s ease-out`,
        '@keyframes slideInFromRight': {
          '0%': {
            opacity: 0,
            transform: 'translateX(100%)',
          },
          '100%': {
            opacity: 1,
            transform: 'translateX(0)',
          },
        },
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          width: '40px',
          height: '40px',
          borderRadius: '20px',
          backgroundColor: '#FFECEE',
          justifyContent: 'center'
        }}>
          <BrowserUpdatedIcon
            sx={{color: '#D7271D', width: '24px', height: '24px'}}/>
        </Box>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
          <Typography sx={{
            fontSize: '14px',
            color: '#424242',
            fontFamily: 'Raleway',
            lineHeight: '16px',
            fontWeight: 500,
            letterSpacing: '0.001em'
          }} component={'span'}>
            A new version of the app is available.
          </Typography>
          <Typography component={'span'} sx={{
            fontSize: '14px',
            color: '#424242',
            fontFamily: 'Montserrat',
            lineHeight: '17px',
            fontWeight: 400,
            letterSpacing: '0.0025em'
          }}>
            Please update to the latest version!
          </Typography>
        </Box>
        <Button
          variant={'text'}
          sx={{
            borderRadius: '20px',
            height: '37px',
            padding: '10px 16px',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            letterSpacing: '0.0025em',
            textTransform: 'uppercase'
          }}
          onClick={async () => await reloadPage()}
        >
          Update
        </Button>
      </Box>
    </Modal>
  )
}
